<template>
  <b-row>
    <b-col cols="12">
      <b-row v-if="successMessage" class="mb-16">
        <b-col>
          <b-alert variant="danger" show dismissible @dismissed="clearSuccessMessage">
            {{ successMessage }}
          </b-alert>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <h2>Change Password</h2>
      <p class="hp-p1-body mb-0">
        Set a unique password to protect your account.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" sm="8" md="7" xl="5" class="col-xxxl-3">
          <b-form @submit.prevent="handleChangePassword">
            <b-form-group label="Old Password :" label-for="oldPassword" class="mb-24">
              <b-form-input id="oldPassword" type="password" placeholder="Old Password" v-model="oldPassword" minlength="8"  required ></b-form-input>
              <small class="text-danger" v-if="password.length > 0 && password.length < 8">
                Password must be at least 8 characters long.
              </small>
            </b-form-group>

            <b-form-group label="New Password :" label-for="newPassword" class="mb-24">
              <b-form-input id="newPassword" type="password" placeholder="New Password" v-model="newPassword"  maxlength="10" required></b-form-input>
               <small class="text-danger" v-if="newPassword.length > 0 && newPassword.length > 10">
                Password must not exceed 10 characters.
              </small>
            </b-form-group>
      
         
            <b-button type="submit" variant="primary" class="w-100"> Change Password </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import { BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BAlert } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BAlert,
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      successMessage: "",
      errorMessage: "",
    };
  },


  methods: {
  handleChangePassword() {
    // Get userType from localStorage
    const userType = localStorage.getItem("type");

    // Determine the API endpoint based on userType
    const apiEndpoint =
      userType === "super_admin"
        ? "https://wossdb.wossautoid.com/api/PasswordChanged"
        : userType === "police"
        ? "https://wossdb.wossautoid.com/api/PasswordChangedPolice"
        : null;

    if (!apiEndpoint) {
      this.errorMessage = "Invalid user type. Cannot change password.";
      return;
    }

    // Call the API to change the password
    axios
      .post(apiEndpoint, {
        old_password: this.oldPassword,
        password: this.newPassword,
      })
      .then((response) => {
        this.successMessage = "Success! Password has been changed.";
        this.clearInputFields();
      })
      .catch((error) => {
        console.error(error);
        this.errorMessage =
          error.response?.data?.message || "Failed to change password.";
      });
      },
      clearInputFields() {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
      },
      clearSuccessMessage() {
        this.successMessage = "";
      },
    },

    };
</script>
