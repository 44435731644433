import axios from 'axios';
// import { reactive } from 'vue'; 
import { reactive, onMounted } from 'vue';
import store from '../store/index';
const state = reactive({
  requestCount: 0, 
});
const fetchRequestCount = async () => {
  try {
    const response = await axios.get('https://wossdb.wossautoid.com/api/requestCount');
    state.requestCount = response.data.data; 
  } catch (error) {

  }
};

onMounted(() => {
  fetchRequestCount();
});

export default {
  // header: "Dashboards",
  children: [
    {
      id: "/dashboard",
      title: "Dashboard",
      iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
      navLink: "/dashboards",
    },
    // Request code here 
    {
      id: "pending",
      title: `Pending User`, 
      icon: "Curved-Graph",
      children: [
        {
          id: "view-request",
          title: "View Pending",
          navLink: "/request/list",
        },
        // {
        //   id: "view-company-request",
        //   title: "View Company Request",
        //   navLink: "/view/company/request",
        // },
      ],
    },
    // User code here 
    {
      id: "user",
      title: "User",
      icon: "Curved-AddUser",
      children: [
        {
          id: "list-user",
          title: "List User",
          navLink: "/view/list/user",
        },
        // {
        //   id: "list-company",
        //   title: "List Company",
        //   navLink: "/view/list/company",
        // },
      ],
    },
    // Vehicle code here 
    {
      id: "vehicle",
      title: "Vehicle",
      icon: "Curved-TicketStar",
      children: [
        {
          id: "add-vehicle",
          title: "Add Vehicle",
          navLink: "/add/vehicle",
        },
        {
          id: "list-vehicle",
          title: "List Vehicle",
          navLink: "/list/vehicle",
        },
        {
          id: "list-theft",
          title: "List Theft",
          navLink: "/list/theft",
        },
        {
          id: "recover-vehicle",
          title: "Recover Vehicle",
          navLink: "/recover/vehicle/theft/list",
        },
      ],
    },
    // Category code here 
    {
      id: "category",
      title: "Vehicle Categories",
      icon: "Curved-Document",
      children: [
        {
          id: "add-category",
          title: "Add Category",
          navLink: "/add/category",
        },
        {
          id: "list-category",
          title: "List Category",
          navLink: "/list/category",
        },
      ],
    },
    // Brand code here 
    {
      id: "brand",
      title: "Vehicle Brands",
      icon: "Curved-Discount",
      children: [
        {
          id: "add-brand",
          title: "Add Brand",
          navLink: "/add/brand",
        },
        {
          id: "list-brand",
          title: "List Brand",
          navLink: "/list/brand",
        },
      ],
    },
    // Model code here 
    {
      id: "model",
      title: "Vehicle Model",
      icon: "Curved-Paper",
      children: [
        {
          id: "add-model",
          title: "Add Model",
          navLink: "/add/model",
        },
        {
          id: "list-model",
          title: "List Model",
          navLink: "/list/model",
        },
      ],
    },
    // Insurance company code here 
    {
      id: "insurance-company",
      title: "Insurance company",
      icon: "Curved-Message",
      children: [
        {
          id: "add-insurance-company",
          title: "Add Insurance company",
          navLink: "/add/insurance/company",
        },
        {
          id: "list-insurance-company",
          title: "List Insurance company",
          navLink: "/list/insurance/company",
        },
      ],
    },
    // MarketPlace code here 
    {
      id: "marketplace",
      title: "MarketPlace",
      icon: "Curved-InfoSquare",
      children: [
        {
          id: "add-marketplace",
          title: "Add MarketPlace",
          navLink: "/add/marketplace",
        },
        {
          id: "list-marketplace",
          title: "List MarketPlace",
          navLink: "/list/marketplace",
        },
      ],
    },
    // Slider code here 
    {
      id: "slider",
      title: "Slider",
      icon: "Curved-Discovery",
      children: [
        {
          id: "add-slider",
          title: "Add Slider",
          navLink: "/add/slider",
        },
        {
          id: "list-slider",
          title: "List Slider",
          navLink: "/list/slider",
        },
      ],
    },
    // Police code here 
    {
      id: "police",
      title: "Police",
      icon: "Curved-AddUser",
      children: [
        {
          id: "add-police",
          title: "Add Police",
          navLink: "/add/police",
        },
        {
          id: "list-police",
          title: "List Police",
          navLink: "/list/police",
        },
      ],
    },
    // Notification code here 
    {
      id: "notification",
      title: "Notification",
      icon: "Curved-Notification",
      children: [
        {
          id: "detail-notification",
          title: "Detail Notification",
          navLink: "/notification/detail",
        },
        {
          id: "detail-notification",
          title: "Send Notification",
          navLink: "/send/notification",
        },
      ],
    },
    // Inspection Location code here 
    {
      id: "inspection-location",
      title: "Inspection Location",
      icon: "Curved-EditSquare",
      children: [
        {
          id: "add-inspection-location",
          title: "Add Inspection Location",
          navLink: "/add/branch",
        },
        {
          id: "list-inspection-location",
          title: "List Inspection Location",
          navLink: "/list/branch",
        },
      ],
    },
    // Schedule code here 
    {
      id: "schedule",
      title: "Schedule",
      icon: "Curved-Filter2",
      children: [
        {
          id: "list-schedule",
          title: "List Schedule",
          navLink: "/list/schedule",
        },
      ],
    },
    // ............................... 
     // Product code here 
     {
      id: "Product",
      title: "Product",
      icon: "Curved-EditSquare",
      children: [
        {
          id: "Product",
          title: "Add Product",
          navLink: "/add/product",
        },
        {
          id: "Product",
          title: "List Product",
          navLink: "/list/product",
        },
      ],
    },
      // Order  code here 
      {
        id: "Order",
        title: "Order",
        icon: "Curved-EditSquare",
        children: [

          {
            id: "Order",
            title: "List Order",
            navLink: "/list/order",
          },
        ],
      },
      
      // Tracker  code here 
      {
        id: "Tracker",
        title: "Tracker",
        icon: "Curved-EditSquare",
        children: [

          {
            id: "Tracker",
            title: "List Tracker",
            navLink: "/list/tracking",
          },

          {
            id: "Tracker",
            title: "Stolen List Tracker",
            navLink: "/list/tracking/stolen",
          },
        ],
      },
         // OwnerShip  code here 
         {
          id: "OwnerShip",
          title: "OwnerShip",
          icon: "Curved-EditSquare",
          children: [
  
            {
              id: "OwnerShip",
              title: "List OwnerShip",
              navLink: "/list/ownership",
            },
  
          
          ],
        },
  ]
}
