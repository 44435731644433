<template>
  <div class="hp-header-logo d-flex align-items-center">
    <!-- <b-link to="/" class="position-relative d-flex">
      <img
        v-if="this.$store.state.themeConfig.direction == 'rtl' && this.$store.state.themeConfig.theme == 'light'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/logo-rtl.svg')"
        alt="logo"
      />
      <img
        v-if="this.$store.state.themeConfig.direction == 'rtl' && this.$store.state.themeConfig.theme == 'dark'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/logo-rtl-dark.svg')"
        alt="logo"
      />
      <img
        v-else-if="this.$store.state.themeConfig.direction == 'ltr' && this.$store.state.themeConfig.theme == 'light'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/logo.svg')"
        alt="logo"
      />
      <img
        v-else-if="this.$store.state.themeConfig.direction == 'ltr' && this.$store.state.themeConfig.theme == 'dark'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/logo-dark.svg')"
        alt="logo"
      />

      <div
        class="position-absolute hp-bg-black-20 hp-bg-dark-90 rounded-circle border hp-border-color-black-0 hp-border-color-dark-90 d-flex align-items-center justify-content-center"
        :style="`width: 18px; height: 18px; top: -5px; ${
          this.$store.state.themeConfig.direction === 'rtl'
            ? 'right: 0px;'
            : 'left: 21px;'
        }`"
      >
        <svg
          class="hp-fill-color-dark-0"
          width="12"
          height="11"
          viewBox="0 0 12 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.557 0H7.5L6 2.45L4.7145 0H0L6 10.5L12 0H9.557ZM1.5 0.875H2.957L6 6.3L9.043 0.875H10.5L6 8.75L1.5 0.875Z"
            fill="#2D3436"
          />
        </svg>
      </div>
    </b-link>

    <a
      href="https://hypeople-studio.gitbook.io/yoda/change-log"
      target="_blank"
      class="hp-caption font-weight-normal hp-text-color-primary-1"
    >
      v.{{ this.$store.state.themeConfig.version }}
    </a> -->
    <b-link to="/" class="position-relative">
     
     <!-- <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-car-front-fill"
       viewBox="0 0 16 16">
       <path
         d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
     </svg> -->
     <img
              class="ml-48 w-50"
               :style="{ aspectRatio: '1 / 1'  }"
              
              :src="
                this.$store.state.themeConfig.theme == 'dark'
                  ? require('@/assets/img/pages/authentication/authentication-bg-dark.svg')
                  // : require('@/assets/img/pages/authentication/Wosslogo1.png')
                  : require('@/assets/img/pages/authentication/wossnewlogo.png')
              "
              alt="Background Image"
            />

   </b-link>

   <!-- <a href="https://hypeople-studio.gitbook.io/yoda/change-log" target="_blank" class="d-flex"> -->
     <!-- <span class="h3 font-weight-bold hp-text-color-1 mb-6">Woss</span> -->
    
   <!-- </a> -->

  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
