<template>
  <div>
    <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
      {{ successMessage }}
    </div>
    <b-card>
      <b-row>
        <div class="col-12 mt-16">
          <b-form @submit.prevent="addUser" v-if="show">
            <div
              style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              "
            >
              <h4
                style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                "
              >
              Schedule Information
              </h4>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Title:" label-for="title">
                  <b-form-input id="title" type="text" placeholder="Enter title" autocomplete="off"
                    v-model="title" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <!-- ....................................  -->
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                  <b-form-input id="first_name" type="text" placeholder="Enter first name" autocomplete="off"
                    v-model="first_name" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                  <b-form-input id="last_name" type="text" placeholder="Enter last name" autocomplete="off"
                    v-model="last_name" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Phone Number:" label-for="phone_number">
                  <b-form-input id="phone_number" type="nuymber" placeholder="Enter phone number" autocomplete="off"
                    v-model="phone_number" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Description:" label-for="description">
                  <b-form-input id="description" type="text" placeholder="Enter description" autocomplete="off"
                    v-model="description" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <!-- .............................................  -->
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Branch Name:" label-for="branch_id">
                  <b-form-input id="branch_id" v-model="branchName" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Schedule Date:" label-for="date">
                  <b-form-input id="date" type="date" placeholder="Enter date" autocomplete="off"
                    v-model="date" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Schedule Time:" label-for="time">
                  <b-form-input id="time" type="time" placeholder="Enter schedule time" autocomplete="off"
                    v-model="time" disabled>
                  </b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Schedule Type:" label-for="type">
                  <b-form-input id="type" type="text" placeholder="Enter schedule type" autocomplete="off"
                    v-model="type" disabled>
                  </b-form-input>
                </b-form-group>
              </div>
              <!-- <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Schedule Status:" label-for="status">
                  <b-form-input id="status" type="text" placeholder="Enter schedule status" autocomplete="off"
                    v-model="status" disabled>
                  </b-form-input>
                </b-form-group>
              </div> -->
            </div>
          </b-form>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";

export default {
  data() {
    return {
      show: true,
      title: "",
      date: "",
      time: "",
      type: "",
      status: "",
      branchName: "",
      branches: [],
      first_name:"",
      last_name:"",
      phone_number:"",
      description:"",
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput
  },
  created() {
    const userId = this.$route.params.id;
    axios
      .get(`scheduleShow/${userId}`)
      .then((response) => {
        this.editedUser = response.data.data;
        this.title = this.editedUser.title ||'';
        this.date = this.editedUser.date;
        this.time = this.editedUser.time;
        this.type = this.editedUser.type;
        this.status = this.editedUser.status;
        this.branchName = this.editedUser.branch.name ||''; 
        this.first_name = this.editedUser.user.first_name ||'';
        this.last_name = this.editedUser.user.last_name ||'';
        this.phone_number = this.editedUser.user.phone_number ||'';
        this.description = this.editedUser.branch.description ||'';
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },
  mounted() {
    axios.post('branch')
      .then(response => {
        if (response.data.success && response.data.data) {
          this.branches = response.data.data.map(branch => ({
            value: branch.id, 
            text: branch.name
          }));
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch(error => {
      });
  }
};
</script>
