<template>
  <div
    class="hover-dropdown-fade w-auto px-0 d-flex align-items-center mr-8 mr-sm-16 position-relative"
  >
    <!-- Notification Button -->
    <b-button variant="text" class="btn-icon-only" @click="togglePopup">
      <i class="iconly-Curved-Notification hp-text-color-black-60 position-relative">
        <!-- Dynamic Notification Badge -->
        <span
          v-if="unreadMessages > 0"
          class="position-absolute translate-middle rounded-circle bg-primary hp-notification-circle"
          style="width: 6px; height: 6px; top: 2px"
        ></span>

        <!-- Display unreadMessages count next to the notification icon -->
        <span
          v-if="unreadMessages > 0"
          class="position-absolute badge bg-primary text-white"
          style="top: -10px; right: -10px; font-size: 12px; padding: 2px 6px"
        >
          {{ unreadMessages }}
        </span>
      </i>
    </b-button>

    <!-- Notification Pop-up -->
    <div
      v-if="showPopup"
      class="hp-notification-menu dropdown-fade position-absolute pt-18"
      style="width: 288px; top: 100%"
    >
      <div
        class="pt-32 pb-18 px-18 rounded border hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80"
      >
        <b-row align-v="center" align-h="between" class="mb-18">
          <b-col
            class="hp-flex-none w-auto h5 hp-text-color-black-100 hp-text-color-dark-10 hp-text-color-dark-0 mr-64 mb-0"
          >
            Notifications
          </b-col>

          <!-- Dynamic Message Count -->
          <b-col
            class="hp-flex-none w-auto hp-bg-color-primary-1 rounded-pill hp-badge-text hp-text-color-black-0 py-4 px-6 mr-12"
          >
            {{ unreadMessages }} New 
          </b-col>
        </b-row>

        <div class="divider my-4"></div>

        <!-- Notification List -->
        <ul class="list-group notification-list">
          <li
            v-for="notification in notifications"
            :key="notification.id"
            class="list-group-item"
          >
            <a @click="viewNotificationDetail(notification.id)"  class="notification-link"  style="cursor: pointer;">
              {{ notification.title }}
              {{ notification.message }}
              <span v-if="notification.viewed === 'false'" class="badge bg-warning">New</span>
                 <!-- Display the notification time -->
                 <div class="text-muted small mt-1">
                {{ formatTime(notification.timestamp) }}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../axios";
import { BRow, BCol, BButton } from "bootstrap-vue";
import Pusher from "pusher-js";
import { mapGetters, mapActions } from 'vuex';


export default {
  props: ['notification'],
  components: {
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      unreadMessages: 0, 
      showPopup: false, 
      notifications: [], 
      type: "", 
      pusher: null,
      channel: null,
      policeSound: null,
      adminSound: null,
    };
  },
  created() {
    this.fetchUserType();
    this.initializePusher();
    this.updateRequestCount();

  },
  computed: {
    ...mapGetters(['requestCount']), // Map the requestCount getter to this component
  },
  methods: {
    updateRequestCount() {
      this.$store.dispatch('fetchRequestCount'); // Dispatch action to fetch request count
    },
    fetchUserType() {
      this.type = localStorage.getItem('type') || 'super_admin'; 
    },

    initializePusher() {
      this.pusher = new Pusher("b89af738d362d3ca27d2", {
        cluster: "ap2",
        encrypted: true,
      });
      
      // Subscribe to the appropriate channel based on user type
      if (this.type === 'police') {
        this.channel = this.pusher.subscribe("vehicle-channel");
        this.channel.bind("client-vehicle-stolen", (data) => {
          // this.notifications.push(data);
          this.notifications.push({
            ...data,
            timestamp: new Date().toISOString(),
          });
          this.unreadMessages += 1; 
          this.showPopup = true; 
          this.playPoliceSound(); 
          this.$bvToast.toast("Notification received successfully!", { 
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 3000,
            variant: "primary",
          });
        });
      } else if (this.type === 'super_admin') {
        this.channel = this.pusher.subscribe("super-admin-channel");
        this.channel.bind("admin-notification", (data) => {
          // this.notifications.push(data);
          this.notifications.push({
            ...data,
            timestamp: new Date().toISOString(),
          });
          this.unreadMessages += 1; 
          this.showPopup = true; 
          this.playAdminSound(); 
          this.$bvToast.toast(data.message || "New notification received", {
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 3000,
            variant: "primary",
          });
        });
          // Second channel and event for 'admin-notification-recovered'
        this.recoveredChannel = this.pusher.subscribe("super-admin-channel-recovered");
        this.recoveredChannel.bind("admin-notification-recovered", (data) => {
        this.notifications.push(data);
        this.unreadMessages += 1; 
        this.showPopup = true;
    
        this.$bvToast.toast(data.message || "Recovered notification received", {
        title: "Recovered",
        variant: "success",
        solid: true,
        appendToast: true,
        toaster: "b-toaster-top-right",
        autoHideDelay: 3000,
        variant: "primary",
      });
    });
      } else {
      
      }
    },

    playPoliceSound() {
      this.stopSounds(); 
      this.policeSound = new Audio('/notification.mp3'); 
      this.policeSound.play().catch((error) => {
      });
    },

    playAdminSound() {
      this.stopSounds(); 
      this.adminSound = new Audio('/admin_notification.mp3'); 
      this.adminSound.play().catch((error) => {
      });
    },

    stopSounds() {
      if (this.policeSound) {
        this.policeSound.pause();
        this.policeSound.currentTime = 0;
      }
      if (this.adminSound) {
        this.adminSound.pause();
        this.adminSound.currentTime = 0;
      }
    },

    // async fetchUnreadMessages() {
    //   try {
    //     let countResponse;
    //     let notificationsResponse;

    //     if (this.type === 'super_admin') {
    //       // Fetch notifications for super_admin
    //       countResponse = await axios.get("NotificationCountAdmin");
    //       notificationsResponse = await axios.get("getUserUnreadNotifications");
    //     } else if (this.type === 'police') {
    //       // Fetch notifications for police
    //       countResponse = await axios.get("NotificationCountPolice");
    //       notificationsResponse = await axios.get("getUnreadNotificationsPolice");
    //     }

    //     // Set unread messages count
    //     this.unreadMessages = countResponse.data.data;
     

    //     // Normalize notifications
    //     if (this.type === 'super_admin') {
    //       this.notifications = notificationsResponse.data.notifications;
    //     } else if (this.type === 'police') {
    //       this.notifications = notificationsResponse.data.notifications.map(item => ({
    //         ...item,
    //         timestamp: item.created_at || new Date().toISOString(),
    //         message: item.notification.message, 
    //       }));
    //     }

    //   } catch (error) {
    //   }
    // },

    async fetchUnreadMessages() {
      try {
        const countResponse = this.type === "super_admin"
          ? await axios.get("NotificationCountAdmin")
          : await axios.get("NotificationCountPolice");

        const notificationsResponse = this.type === "super_admin"
          ? await axios.get("getUserUnreadNotifications")
          : await axios.get("getUnreadNotificationsPolice");

        this.unreadMessages = countResponse.data.data;

        this.notifications = notificationsResponse.data.notifications.map((item) => ({
          ...item,
          timestamp: item.created_at || new Date().toISOString(),
          message: item.notification?.message || item.message || "No message",
        }));
      } catch (error) {
        console.error(error);
      }
    },
    
    formatTime(timestamp) {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        this.fetchUnreadMessages();
        this.stopSounds();
      }
    },

    async markNotificationAsViewed(id) {
      try {
        let response;
        
        if (this.type === 'super_admin') {
          response = await axios.get(`markAsViewedNotification/${id}`);
          response = await axios.get(`showNotification/${id}`);
        } else if (this.type === 'police') {
          response = await axios.get(`markAsViewedNotificationPolice/${id}`);
          response = await axios.get(`showUserNotificationPolice/${id}`);
        }
        // Handle the response
        if (response && response.data.success) {
          const notification = this.notifications.find(n => n.id === id);
          if (notification) notification.viewed = "true";
          this.unreadMessages = Math.max(0, this.unreadMessages - 1);
        }
      } catch (error) {
      }
    },

    viewNotificationDetail(id) {
      // Stop notification sound when viewing details
      this.stopSounds();

      // Mark notification as viewed based on user type
      this.markNotificationAsViewed(id);
      
      // Navigate to the detail page
      if (this.type === 'super_admin') {
        this.$router.push({
          name: "DetailNotificationPage", // Super admin's detail notification page
          params: { id }, 
        });
      } else if (this.type === 'police') {
        this.$router.push({
          name: "PoliceNotificationDetailPage", 
          params: { id },
        });
      }
    },
  },

  mounted() {
    this.fetchUnreadMessages();
    
  },
};
</script>

<style scoped>
.notification-detail-page {
  padding: 20px;
}

.card-title {
  font-weight: bold;
}

.card-text {
  margin-bottom: 10px;
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px; 
}
.notification-link {
  font-weight: bold;
}
</style>