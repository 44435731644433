<template>
  <div
    class="hp-header-search-item position-relative"
    v-click-outside="hideModal"
  >
    <b-form-input
      placeholder="Search..."
      autocomplete="off"
      v-model="state"
      @input="onSearchInput"
    />

    <div v-if="modal && filteredStates.length > 0" class="autocomplete-suggestions">
      <b-link
        class="autocomplete-suggestion text-black-100 hp-text-color-dark-0 d-block"
        :to="filteredState.url"
        :key="index"
        v-for="(filteredState, index) in filteredStates"
        @click="setState(filteredState.title)"
      >
        {{ filteredState.title }}
      </b-link>
    </div>
  </div>
</template>

<script>
import { BFormInput, BLink } from "bootstrap-vue";
import ClickOutside from 'vue-click-outside';

export default {
  props: ["searchActive"],
  data() {
    return {
      state: "",
      modal: false,
      // Define all possible modules
      allStates: [
        { title: "RecoverVehicleListPolice", url: "/recover/list/police/vehicle" },
        { title: "ListTheftPolice", url: "/list/theft/vehicle" },
        { title: "ListRequest", url: "/request/list" },
        { title: "ListUser", url: "/view/list/user" },
        { title: "AddPolice", url: "/add/police" },
        { title: "ListPolice", url: "/list/police" },
        { title: "RecoverVehicle", url: "recover/vehicle" },
        { title: "RecoverVehicleListTheft", url: "/recover/vehicle/theft/list" },
        { title: "AddVehicle", url: "/add/vehicle" },
        { title: "ListVehicle", url: "/list/vehicle" },
        { title: "AddBrand", url: "/add/brand" },
        { title: "ListBrand", url: "/list/vehicle" },
        { title: "AddCategory", url: "/add/category" },
        { title: "ListCategory", url: "/list/category" },
        { title: "AddModel", url: "/add/model" },
        { title: "ListModel", url: "/list/model" },
        { title: "ListTheft", url: "/list/theft" },
        { title: "AddInsurance", url: "/add/insurance/company" },
        { title: "ListInsurance", url: "/list/insurance/company" },
        { title: "AddMarketPlace", url: "/add/marketplace" },
        { title: "ListMarketPlace", url: "/list/marketplace" },
        { title: "ListNotification", url: "/notification/detail" },
        { title: "AddSlider", url: "/add/slider" },
        { title: "ListSlider", url: "/list/slider" },
        { title: "AddInspection", url: "/add/branch" },
        { title: "ListInspection", url: "/list/branch" },
        { title: "ListSchedule", url: "/list/schedule" },
        { title: "AddProduct", url: "/add/product" },
        { title: "ListProduct", url: "/list/product" },
        { title: "ListOrder", url: "/list/order" },
        { title: "PoliceAllNotification", url: "/police/all/notification" },
        { title: "TrackingList", url: "/list/tracking" },
        { title: "StolenTrackingList", url: "/list/tracking/stolen" },
        { title: "ListOwnerShip", url: "/list/ownership" },
      ],
    };
  },
  components: {
    BFormInput,
    BLink,
  },
  computed: {
    filteredStates() {
      const userType = localStorage.getItem('type'); // Get user type from local storage

      // Filter based on user type and search term
      let statesByUserType = [];
      if (userType === 'police') {
        statesByUserType = this.allStates.filter((item) =>
          ["RecoverVehicleListPolice", "ListTheftPolice","PoliceAllNotification"].includes(item.title)
        );
      } else if (userType === 'super_admin') {
        statesByUserType = this.allStates.filter((item) =>
          [
            "ListRequest",
            "ListUser",
            "AddPolice",
            "ListPolice",
            "RecoverVehicle",
            "RecoverVehicleListTheft",
            "AddVehicle",
            "ListVehicle",
            "AddBrand",
            "ListBrand",
            "AddCategory",
            "ListCategory",
            "AddModel",
            "ListModel",
            "ListTheft",
            "AddInsurance",
            "ListInsurance",
            "AddMarketPlace",
            "ListMarketPlace",
            "ListNotification",
            "AddSlider",
            "ListSlider",
            "AddInspection",
            "ListInspection",
            "ListSchedule",
            "AddProduct",
            "ListProduct",
            "ListOrder",
            "TrackingList",
            "StolenTrackingList",
            "ListOwnerShip"
          ].includes(item.title)
        );
      }

      // Apply the search filter to the selected modules based on `state` input
      return statesByUserType.filter((item) =>
        item.title.toLowerCase().includes(this.state.toLowerCase())
      );
    },
  },
  methods: {
    setState(state) {
      this.state = state;
      this.modal = false;
      this.$emit("searchClose");
    },
    hideModal() {
      this.modal = false;
    },
    onSearchInput() {
      this.modal = this.state.trim() !== ""; // Show modal only if there's input
    }
  },
  directives: {
    ClickOutside,
  },
};
</script>

