<template>
    <b-col cols="12" xl="12">
      <b-card id="invoice" class="card border-0 hp-invoice-card">
        <b-row align-h="between">
        
          <b-col cols="12" lg="10">
            <img
              class="hp-logo mb-16"
              :src="
                this.$store.state.themeConfig.theme == 'dark'
             
                ? require('@/assets/img/pages/authentication/authentication-bg-dark.svg')
                : require('@/assets/img/pages/authentication/Wosslogo1.png')
              "
              alt="logo"
              style="width: 200px; height: auto;"
            />
            </b-col>
            <b-col lg="2" class="space-x-0">
              <b-button @click="downloadPdf" variant="primary" class="mb-8 mr-8 exclude-from-pdf">Download</b-button>
            </b-col>
        
  
          <b-col cols="12" lg="12" class="d-flex justify-content-center align-items-center">
            <h2 class="hp-p1-body mb-16 text-center" style="color: black; font-weight: bold; font-size: 28px;">
              CERTIFICADO DE INSTALACIÓN
            </h2>
          </b-col>
        
  
          <b-col cols="6">
      
            <div style="display: flex; align-items: center; margin-bottom: 10px;">
              <p style="color: black; font-size: 12px; margin: 0; width: 200px;">
                SEGURO 
            </p>
            <div contenteditable="true" style="border-bottom: 1px solid black; flex: 1;
            margin-left: 2px; font-weight: bold;"></div>
            </div>
        
            <div style="display: flex; align-items: center; margin-bottom: 10px;">
              <p style="color: black; font-size: 12px; margin: 0; width: 200px;">
                PÓLIZA NÚMERO 
            </p>
            <div contenteditable="true" style="border-bottom: 1px solid black; flex: 1;
            margin-left: 2px; font-weight: bold;"></div>
            </div>
          </b-col>
          <b-col cols="6">
          
  
            <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <p style="color: black; font-size: 12px; margin: 0; width: 200px;">
            CÓDIGO DE IDENTIFICACIÓN UNICO (CIU)
          </p>
          <div contenteditable="true" style="border-bottom: 1px solid black; flex: 1; margin-left: 10px; font-weight: bold;">{{ uic }}</div>
        </div>
      
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
        <p style="color: black; font-size: 12px; margin: 0; width: 200px;">
          FECHA DE SOLICITUD
        </p>
        <input
          type="date"
          style="border: none; border-bottom: 1px solid black; flex: 1; margin-left: 10px; font-weight: bold; outline: none; background: transparent;"
        />
      </div>
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <p style="color: black; font-size: 12px; margin: 0; width: 200px;">
            NÚMERO DE FACTURA
          </p>
          <div contenteditable="true" style="border-bottom: 1px solid black; flex: 1; margin-left: 10px;font-weight: bold;"></div>
        </div>
  
            
          </b-col>
        </b-row>
        
        <b-row align-h="between">
  <!-- DISTRIBUTOR/SALESPOINT Table -->
  <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black; table-layout: fixed;">
    <thead>
      <tr>
        <th colspan="2" class="text-center" style="color: black; font-size: 18px; background-color: gray;"> I S T R I B U I D O R O P U N T O D E V E N T A</th>
      </tr>
    </thead>
    <tbody>
      <!-- First row: Name and Address -->
      <tr>
        <td style="width: 50%; height: 30px; overflow: hidden; text-align: center; vertical-align: middle;">NOMBRE</td>
        <td style="width: 50%; height: 30px; overflow: hidden; text-align: center; vertical-align: middle;">DIRECCIÓN
        </td>
      </tr>
      <!-- Second row: Empty cell -->
      <tr>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle; ">{{  }}</td>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
      </tr>
      <!-- Third row: SELLER and empty cell -->
      <tr>
        <td  style="width: 50%; height: 30px; overflow: hidden; text-align: center; vertical-align: middle">VENDEDOR</td>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
      </tr>
      <!-- Fourth row -->
      <tr>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
      </tr>
      <tr>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
        <td contenteditable="true" style="width: 50%; height: 30px; overflow: hidden;font-weight: bold; text-align: center; vertical-align: middle"></td>
      </tr>
    </tbody>
  </table>
  </b-col>
  
  <!-- .............Personal Data.........  -->
   
  <div v-if="type === 'user'">
    <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black; table-layout: fixed;">
    <thead>
  
      <tr>
        <th colspan="4" class="text-center" style="color: black; font-size: 18px; background-color: gray;">
          D A T O S P E R S O N A L E S
        </th>
      </tr>
  
      <tr>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          PRIMER NOMBRE
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          SEGUNDO NOMBRE
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          PRIMER APELLIDO 
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          SEGUNDO APELLIDO
        </th>
      </tr>
    </thead>
  
    <tbody>
         <!-- first  row  -->
    
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ first_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ second_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ last_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ second_last_name }}</td>
      </tr>
      <!-- third  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400">FECHA DE NACIMIENTO</td>
        <td class="bg-gray-100 text-center border-r border-gray-400">TEL. DOMICILIO </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">CELULAR </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">TEL. TEL. AGENCIA</td>
      </tr>
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ birthdate }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{  }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ phone_number }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold"></td>
      </tr>
      <!-- fouth  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">DIRECCIÓN DOMICILIO </td>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">CORREO ELECTRÓNICO</td>
      </tr>
      <tr>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ street_address }}</td>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ email }}</td>
      </tr>
    </tbody>
  </table>
  </b-col>
  </div>
  <!-- Company  Data  -->
  
  <div v-if="type === 'company'">
    <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black; table-layout: fixed;">
    <thead>
  
      <tr>
        <th colspan="4" class="text-center" style="color: black; font-size: 18px; background-color: gray;">
          D A T O S P E R S O N A L E S
        </th>
      </tr>
  
      <tr>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          PRIMER NOMBRE
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          SEGUNDO NOMBRE
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          PRIMER APELLIDO 
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          SEGUNDO APELLIDO
        </th>
      </tr>
    </thead>
  
    <tbody>
         <!-- first  row  -->
    
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ first_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ second_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ last_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ second_last_name }}</td>
      </tr>
      <!-- third  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400">FECHA DE NACIMIENTO</td>
        <td class="bg-gray-100 text-center border-r border-gray-400">TEL. DOMICILIO </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">CELULAR </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">TEL. TEL. AGENCIA</td>
      </tr>
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ birthdate }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{  }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ phone_number }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold"></td>
      </tr>
      <!-- fouth  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">DIRECCIÓN DOMICILIO </td>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">CORREO ELECTRÓNICO</td>
      </tr>
      <tr>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ street_address }}</td>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ email }}</td>
      </tr>
    </tbody>
  </table>
  </b-col>
    <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black; table-layout: fixed;">
    <thead>
  
      <tr>
        <th colspan="4" class="text-center" style="color: black; font-size: 18px; background-color: gray;">
          A T O S DE LA EMPRESA
        </th>
      </tr>
  
      <tr>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          RAZÓN SOCIAL
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          RFC
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400" colspan="2">
          OMBRE COMERCIAL

        </th> 
      </tr>
    </thead>
  
    <tbody>
         <!-- first  row  -->
    
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ company_name }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ rfc }}</td>
        <td colspan="2" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ trade_name }}</td>
        
      </tr>
      <!-- third  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400">FECHA DE CONSTITUCIÓN</td>
        <td class="bg-gray-100 text-center border-r border-gray-400">TEL. OFICINA </td>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2">TEL. CELULAR</td>
      </tr>
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{created_at}}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{office_phone}}</td>
        <td colspan="2" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ phone_number }}</td>
      </tr>
      <!-- fouth  row  -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">DIRECCIÓN DOMICILIO </td>
        <td class="bg-gray-100 text-center border-r border-gray-400" colspan="2" style="height: 30px;">CORREO ELECTRÓNICO</td>
      </tr>
      <tr>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ street_address }}</td>
        <td colspan="2" contenteditable="true" style="height: 70px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ email }}</td>
      </tr>
    </tbody>
  </table>
  </b-col>
  </div>
     <!-- .......................  -->
  <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black; table-layout: fixed;">
    <thead>
      <!-- Main Heading Row -->
      <tr>
        <th colspan="4" class="text-center" style="color: black; font-size: 18px; background-color: gray;">
          D A T O S D E L V E H I C U L O
        </th>
      </tr>
      <!-- Sub Headings Row -->
      <tr>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          IN-MARK 
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400">
          VIN-ETCHING 
        </th>
        <th class="bg-gray-100 text-center py-2 border-r border-gray-400" colspan="2">
          PROPIETARIO
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- First row: Example Data -->
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold"></td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold"></td>
      
        <td colspan="2" contenteditable="true" style="height: 30px; font-size: 8px; text-align: center; vertical-align: middle;">
           
        </td>
  
      </tr>
      <!-- Second row: Example Data -->
      <tr>
        <td class="bg-gray-100 text-center border-r border-gray-400">MARCA 
        </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">DESCRIPCIÓN 
        </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">AÑO MODELO 
        </td>
        <td class="bg-gray-100 text-center border-r border-gray-400">COLOR
        </td>
      </tr>
      <!-- Third  Row :Example  Data  -->
      <tr>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{name}}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden;text-align: center; vertical-align: middle; font-weight: bold">{{  }}</td>
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden;text-align: center; vertical-align: middle; font-weight: bold">{{ year }}</td> 
        <td colspan="1" contenteditable="true" style="height: 30px; width: 25%; overflow: hidden;text-align: center; vertical-align: middle; font-weight: bold">{{ color }}</td>
      </tr>
      <!-- Four  Row :Example  Data  -->
      <tr>
        <td  class="bg-gray-100 text-center py- border-r border-gray-400" 
        colspan="2" style="height: 30px;">NÚMERO IDENTIFICADOR VEHICULAR (VIN)  </td>
        <td class="bg-gray-100 text-center py- border-r border-gray-400" colspan="2" style="height: 30px;">PLACA
        </td>
        
      </tr>
      <!-- Five  Row :Example  Data  -->
      <tr>
        <td colspan="2" contenteditable="true" style="height: 30px; width: 50%; overflow: hidden;text-align: center; vertical-align: middle; font-weight: bold">{{ vin }}</td>
        <td colspan="2" contenteditable="true" style="height: 30px; width: 50%; overflow: hidden; text-align: center; vertical-align: middle; font-weight: bold">{{ license_plate }}</td>
      </tr>
    </tbody>
  </table>
  </b-col>
  
  <!-- ..............  -->
  <b-col cols="12" md="12" class="pb-16 hp-print-info">
  <table border="1" cellpadding="5" cellspacing="0" style="width: 100%; border-color: black;">
    <thead>
      <tr>
        <th colspan="2" class="text-center" style="color: black; font-size: 18px; background-color: gray;">
          C E R T I F I C A C I Ó N D E I N S T A L A C I Ó N
  
        </th>
      </tr>
    </thead>
  </table>
      <div>
        
                   <!-- Content for user type -->
          <div v-if="type === 'user'">
            <p style="color: black; font-size: 12px;">Constancia de Identificación Vehicular - WOSS</p>
            <p style="color: black; font-size: 12px;">
              Por medio del presente documento, se certifica la identificación y el regristro del vehículo cuyas características se detallan en la planilla anexa. Este vehículo ha sido 
              identificado mediante el sistema WOSS AUTO-MARK, que utiliza el Número de Identificación Vehicular (VIN) grabado en 16 puntos estratégicos de la carrocería, creando 
              una huella digital permanente e inalterable vinculada al sistema WOSS AUTO-ID.
            </p>
            <p style="color: black; font-size: 12px;">
              El proceso de marcado ha sido realizado conforme a las normas internacionales más estrictas en materia de registro e identificación vehicular, con la autorización expresa 
              del propietario. WOSS (World On Security Systems) garantiza que el uso de WOSS AUTO-MARK en conjunto con WOSS AUTO-ID contribuye significativamente a dificultar 
              cualquier intento de manipulación o alteración no autorizada del vehículo, incrementando la protección contra actividades fraudulentas y proporcionando una capa adicional 
              de seguridad.
            </p>
            <p style="color: black; font-size: 12px;">
              WOSS está comprometido en ofrecer soluciones avanzadas y confiables en seguridad vehicular, brindando tranquilidad y confianza tanto a los propietarios de vehículos 
              como a nuestros socios estratégicos.
            </p>
            <p style="color: black; font-size: 12px;">
              Si aún no lo ha hecho, descargue la App de WOSS para gestionar de manera sencilla y segura la información de su vehículo y aprovechar todas las ventajas que ofrece 
              nuestro sistema.
            </p>
          </div>

          <!-- Content for non-user type -->
          <div v-else>
            <p style="color: black; font-size: 12px;">Constancia de Identificación Vehicular - WOSS</p>
            <p style="color: black; font-size: 12px;">
              Por medio del presente documento, se certifica la identificación y el regristro del vehículo cuyas características se detallan en la planilla anexa. Este vehículo ha sido 
              identificado mediante el sistema WOSS AUTO-MARK, que utiliza el Número de Identificación Vehicular (VIN) grabado en 16 puntos estratégicos de la carrocería, creando 
              una huella digital permanente e inalterable vinculada al sistema WOSS AUTO-ID.
            </p>
            <p style="color: black; font-size: 12px;">
              El proceso de marcado ha sido realizado conforme a las normas internacionales más estrictas en materia de registro e identificación vehicular, con la autorización expresa 
              del propietario. WOSS (World On Security Systems) garantiza que el uso de WOSS AUTO-MARK en conjunto con WOSS AUTO-ID contribuye significativamente a dificultar 
              cualquier intento de manipulación o alteración no autorizada del vehículo, incrementando la protección contra actividades fraudulentas y proporcionando una capa adicional 
              de seguridad.
            </p>
            <p style="color: black; font-size: 12px;">
              WOSS está comprometido en ofrecer soluciones avanzadas y confiables en seguridad vehicular, brindando tranquilidad y confianza tanto a los propietarios de vehículos 
              como a nuestros socios estratégicos.
            </p>
            <p style="color: black; font-size: 12px;">
              Si aún no lo ha hecho, descargue la App de WOSS para gestionar de manera sencilla y segura la información de su vehículo y aprovechar todas las ventajas que ofrece 
              nuestro sistema. Para más información visite nuestra página web
              <a href="https://woss911.com/" style="color: blue; text-decoration: underline;" target="_blank">www.woss911.com</a>.
            </p>
          
    </div>
      </div>

  </b-col>
  
  </b-row>
  <!-- ended  row  -->
  
   
       <b-row>
    <b-col cols="12">
      <b-table-simple small caption-top responsive borderless>
      <b-thead>
        <b-tr>
          <!-- Seller Signature -->
          <b-th scope="col" class="pt-0 pb-14 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
              <!-- Description Line with Border -->
              <p style="color: black; font-size: 12px; margin: 0; border-bottom: 1px solid black;"></p>
              <!-- Centered Text Below the Line -->
              <p style="color: black; font-size: 8px; margin: 0; text-align: center;">
                Nombre y Firma
             <br />del Vendedor
              </p>
            </div>
          </b-th>
  
          <!-- Installer Signature -->
          <b-th scope="col" class="pt-0 pb-14 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
              <!-- Description Line with Border -->
              <p style="color: black; font-size: 12px; margin: 0; border-bottom: 1px solid black;"></p>
              <!-- Centered Text Below the Line -->
              <p style="color: black; font-size: 8px; margin: 0; text-align: center;">
                Nombre y Firma <br />
                del Instalador
              </p>
             
            </div>
          </b-th>
          <!-- Owner/Representative Signature -->
          <b-th scope="col" class="pt-0 pb-14 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
              <!-- Description Line with Border -->
              <p style="color: black; font-size: 12px; margin: 0; border-bottom: 1px solid black;"></p>
              <!-- Centered Text Below the Line -->
              <p style="color: black; font-size: 8px; margin: 0; text-align: center;">
                Nombre y Firma ó Sello <br />del Propietario ó Representante
              </p>
            </div>
          </b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </b-col>
  </b-row>
  <!-- ........................ -->
  <b-row>
  <b-col cols="12">
    <b-table-simple small caption-top responsive borderless>
      <b-thead>
        <b-tr>
          <!-- Seller Signature -->
          <b-th scope="col" class="pt-0 pb-18 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
             
              <!-- Additional Row for Installation Date -->
              <b-row align-h="between" class="mt-0">
                <b-col cols="6" lg="8" class="text-left">
                  <p style="color: black; font-size: 8px; margin: 14px;">Fecha de Instalación:</p>
                </b-col>
              </b-row>
            </div>
          </b-th>
          <!-- Installer Signature -->
          <b-th scope="col" class="pt-0 pb-18 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
             
               <!-- Centered Image -->
               <div style="text-align: center; margin: 0px;">
                <img src="@/assets/img/pages/authentication/Wossten.jpg" alt="Image" style="max-height: 50px;" />
              </div>
            </div>
          </b-th>
          <b-th scope="col" class="pt-0 pb-18 bg-transparent hp-invoice-table-desc">
            <div style="width: 250px; text-align: center; display: inline-block;">
            </div>
          </b-th>
  
        </b-tr>
          </b-thead>
        </b-table-simple>
      </b-col>
      </b-row>
  
      </b-card>
    </b-col>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCard,
  } from "bootstrap-vue";
  import axios from "../../../axios";
  import Papa from "papaparse";
  import html2pdf from "html2pdf.js";
  
  export default {
    data() {
    return {
      type: "user",
      show: true,
      vehicleId: "",
      fileToUpload: null,
      documents: null,
      isLoading: false,
      successMessage: "",
      first_name: "",
      email:"",
      userId: "",
      second_name:"", 
      last_name:"",
      second_last_name:"",
      birthdate:"",
      street_address:"",
      vin:"",
      color:"",
      license_plate:"",
      name:"",
      phone_number:"",
      year:"",
      trade_name:"",
      rfc:"",
      company_name:"",
      features:"",
      uic:"",
    };
  },

    components: {
      BRow,
      BCol,
      BButton,
      BTableSimple,
      BThead,
      BTbody,
      BTr,
      BTh,
      BTd,
      BCard,
    },
    created() {
    const userId = this.$route.params.id;
    axios
      .get(`vehicleShow/${userId}`)
      .then((response) => {
          const data = response.data.data;
          const user = data.user;
          this.created_at = user.created_at
          ? new Date(user.created_at).toISOString().split("T")[0] 
          : "Date not available";
          this.first_name = user.first_name; 
          this.email = user.email;
          this.type = user.type;
          this.second_name = user.second_name;
          this.last_name = user.last_name;
          this.second_last_name = user.second_last_name;
          this.birthdate = user.birthdate;
          this.street_address = user.street_address;
          this.second_last_name = user.second_last_name;
          this.phone_number = user.phone_number;
          this.rfc = user.rfc ||'';
          this.office_phone = user.office_phone ||'';
          this.company_name = user.company_name ||'';
          this.trade_name = user.trade_name ||'';
          this.vin = data.vin;
          this.year = data.year;
          this.color = data.color;
          this.uic = data.uic ||'';
          this.license_plate = data.license_plate ||'';
          this.name = data.brand.name;

      })
      .catch((error) => {
          console.error("Error fetching user data:", error);
      });
  
  },
  mounted() {
  
  this.userId = this.$route.params.id;
  },

  methods: {
  downloadPdf() {
    const invoiceElement = document.getElementById('invoice');
    const excludeElements = document.querySelectorAll('.exclude-from-pdf');
    
    // Temporarily hide elements with the 'exclude-from-pdf' class
    excludeElements.forEach((el) => {
      el.style.display = 'none';
    });

    const isUser = this.type === 'user'; 
    const isCompany = this.type === 'company';

    // Common options
    const options = {
      filename: 'installation_certificate.pdf',
      html2canvas: { scale: isUser ? 2 : (isCompany ? 2 : 1.5) }, 
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { avoid: ['table', '.avoid-break'] } 
    };

    if (isUser) {
      invoiceElement.style.transform = 'scale(0.7)';
      invoiceElement.style.transformOrigin = 'top center';
      invoiceElement.style.width = '100%';
      invoiceElement.style.margin = '0 auto';
    }

      else if (isCompany){
      invoiceElement.style.transform = 'scale(0.6)';
      invoiceElement.style.transformOrigin = 'top center';
      invoiceElement.style.width = '100%';
      invoiceElement.style.margin = '0 auto'; 
    }

    html2pdf()
      .from(invoiceElement)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {

      
      const totalPages = pdf.internal.getNumberOfPages();

            // Ensure no extra empty pages for users
            if ((isUser || isCompany) && totalPages > 1) {
              pdf.deletePage(totalPages);
            }


            // Additional scaling adjustments if content overflows
            const totalHeight = pdf.internal.pageSize.getHeight();
            const totalWidth = pdf.internal.pageSize.getWidth();

            if (
              invoiceElement.offsetHeight > totalHeight ||
              invoiceElement.offsetWidth > totalWidth
            ) {
              invoiceElement.style.transform = 'scale(0.8)';
              invoiceElement.style.width = '100%';
              invoiceElement.style.margin = '0 auto';
            }
            })
            .save()
            .finally(() => {
            // Restore the original styling
            excludeElements.forEach((el) => {
              el.style.display = '';
            });
            invoiceElement.style.transform = '';
            invoiceElement.style.width = '';
            invoiceElement.style.margin = '';
            });
              }
            }
  };
  </script>
  