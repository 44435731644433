   <template>
    <div class="notification-detail-page">
      <b-card class="mb-3" header="Notification Details">
        <h4 class="card-title blue-title">Title: {{ notification.title }}</h4>
        <p class="card-text"><strong>Message:</strong> {{ notification.notification.message }}</p>
      
      <p class="card-text">
        <strong>Vehicle Identification Number:</strong>
        <router-link :to="{ name: 'ListTheftPolice', params: { vin: notification.notification.vehicle.vin } }">
          {{ notification.notification.vehicle.vin }}
        </router-link>
      </p>
        <p class="card-text">
          <strong>Registration Number:</strong> {{ notification.notification.vehicle.registration_number }}
        </p>
        <p class="card-text">
        <strong>Date and Time:</strong> {{ formattedDate }}
      </p>
  
        <b-button variant="primary" @click="showDeleteModal" class="mt-3">Delete</b-button>
      </b-card>
  
      <b-modal
        id="delete-confirmation-modal"
        ref="deleteModal"
        title="Confirm Delete"
        @ok="deleteNotification"
        ok-title="Confirm"
        ok-variant="danger"
        cancel-title="Cancel"
        hide-footer
      >
        <p>Are you sure you want to delete this notification?</p>
        <b-button variant="primary" @click="confirmDelete" class="mr-2">Confirm</b-button>
        <b-button variant="primary" @click="cancelDelete">Cancel</b-button>
      </b-modal>
    </div>
  </template>

<script>
// import axios from "axios";
import axios from "../../../axios";
import { BCard, BButton, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BModal,
  },
  data() {
    return {
      notification: {}, 
      formattedDate: '',
    };
  },
  methods: {
    async fetchNotificationDetail() {
      const id = this.$route.params.id;
      try {
        const response = await axios.get(`showUserNotificationPolice/${id}`);
        this.notification = response.data.notification; 
         this.formattedDate = this.formatDate(this.notification.created_at);
      } catch (error) {
      
      }
    },
     
     formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); 
    },
  
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },

    // Method to delete the notification
    async deleteNotification() {
      const id = this.$route.params.id; 
      try {
        await axios.delete(`destroyNotificationPolice/${id}`); 
        this.$refs.deleteModal.hide(); 
        // Redirect or refresh after deletion
        this.$router.push("/policedashboard"); 
      } catch (error) {
      
      }
    },

    // Confirm deletion
    confirmDelete() {
      this.deleteNotification(); 
    },

    // Cancel deletion and close the modal
    cancelDelete() {
      this.$refs.deleteModal.hide();
    },
  },
  mounted() {
    // Fetch the notification details when the component is mounted
    this.fetchNotificationDetail();
  },
  watch: {
    // Watch for route changes and re-fetch notification when the ID changes
    '$route.params.id'(newId) {
      this.fetchNotificationDetail(); 
    }
  },
};
</script>

<style scoped>
.blue-title {
  color: blue;
}
</style>