<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
          <div class="col-12 mt-16">
            <b-form @submit.prevent="addUser" v-if="show">
              <div style="background-color: rgb(97, 116, 152); height: 32px; border-radius: 4px;">
                <h4 style="color: rgb(223, 227, 238); margin-left: 5px; font-weight: bold;">
                  Notification Information
                </h4>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Title:" label-for="title">
                    <b-form-input
                      id="title"
                      type="text"
                      placeholder="Enter title"
                      autocomplete="off"
                      v-model="title"
                      required
                    />
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Message:" label-for="message">
                    <b-form-input
                      id="message"
                      type="text"
                      placeholder="Enter message"
                      v-model="message"
                      required
                    />
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group id="user-group" label="Select User:" label-for="user">
                    <MultiSelect
                      v-model="selectedUsers"
                      :options="userOptions"
                      optionLabel="fullName"
                      filter
                      placeholder="Select User"
                      :maxSelectedLabels="3"
                      class="fixed-width"
                    />
                  </b-form-group>
                </div>
              </div>
              <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                <span v-if="!isLoading">Submit</span>
                <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
              </b-button>
            </b-form>
          </div>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
  } from "bootstrap-vue";
  import axios from "../../../axios";
  import MultiSelect from "primevue/multiselect";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";
  
  export default {
    data() {
      return {
        show: true,
        isLoading: false,
        title: "",
        message: "",
        users: [],
        selectedUsers: [],
        successMessage: "",
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BSpinner,
      MultiSelect,
    },
    computed: {
      userOptions() {
        return this.users.map((user) => ({
          id: user.id,
          fullName: user.first_name,
        }));
      },
    },
    created() {
      this.fetchUsers();
    },
    methods: {
      fetchUsers() {
        axios
          .get("approvedAllUsersAndCompanyList")
          .then((response) => {
            this.users = response.data.data;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      addUser() {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("title", this.title);
        formData.append("message", this.message);
        this.selectedUsers.forEach((user) => {
          formData.append("user_id[]", user.id);
        });
  
        axios
          .post("sendNotificationToUsers", formData)
          .then((response) => {
          this.$bvToast.toast("Notification sent successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 5000,
            variant: "primary",
          });
          this.isLoading = false;
        })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      },
    },
  };
  </script>
  
  <style>
  .fixed-width {
    width: 100%;
    border-radius: 6px;
  }
  </style>
  
