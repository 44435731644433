     <template>
      <div>
        <h1>Stolen Location Details</h1>
        <div v-if="stolenLocationDetails">
          <p><strong>Location:</strong> {{ stolenLocationDetails.stolen_location }}</p>
          <p>
            <strong>Coordinates:</strong>
            {{ stolenLocationDetails.latitude }}, {{ stolenLocationDetails.longitude }}
          </p>
        </div>
        <div id="map" class="map-container"></div>
      </div>
    </template>
    
    <script>
    export default {
      data() {
        return {
          map: null,
          marker: null,
          stolenLocationDetails: null,
        };
      },
      mounted() {
        // Retrieve data from the query parameters
        const { stolen_location, latitude, longitude } = this.$route.query;
    
        if (stolen_location && latitude && longitude) {
          this.stolenLocationDetails = {
            stolen_location,
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          };
    
          // Initialize the map
          this.initMap(this.stolenLocationDetails.latitude, this.stolenLocationDetails.longitude);
        }
      },
      methods: {
        initMap(lat, lng) {
          const position = { lat, lng };
    
          this.map = new google.maps.Map(document.getElementById("map"), {
            center: position,
            zoom: 12,
          });
    
          this.marker = new google.maps.Marker({
            position,
            map: this.map,
          });
        },
      },
    };
    </script>
    
    <style>
    .map-container {
      width: 100%;
      height: 500px;
      margin-top: 20px;
    }
    </style>
    