<template>
    <b-card>
        <!-- filter  -->
        <div v-if="loading" class="text-center mt-4">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="col-12 mt-16">
            <div>
                <b-row class="align-items-center">
                    <b-col lg="4" class="my-1">
                        <b-form-group label="" label-for="filter-input" label-cols-sm="1" label-align-sm="right"
                            label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search"
                                    placeholder="Type to Search"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="applyFilter" :disabled="!filter"
                                        variant="primary">Apply</b-button>
                                <b-button :disabled="!filter" @click="clearFilter">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                       <!-- .. Filter  for   date-->
                       <b-col lg="3" class="my-1">
                    <b-form-input id="start-date" v-model="startDateFilter" type="date"
                        placeholder="Select start date"></b-form-input>
                </b-col>
                <b-col lg="3" class="my-1">
                    <b-form-input id="end-date" v-model="endDateFilter" type="date" placeholder="Select end date"
                        @change="changeDate"></b-form-input>
                </b-col>
                    <!-- Filter  for   date-  -->
                   
                    <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv"
                        style="display: none;" />
                    <b-col lg="2" class="my-1 d-flex justify-content-end">
                      <b-button @click="exportAllDataToCSV" variant="primary" class="mb-8 mr-8">Export</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
     

        <!-- filter end -->
        <b-row>
            <div class="col-12 mt-16">
                <b-table id="dataTable" :items="users" :busy.sync="loading" :fields="fields" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty responsive>


                    <template #cell(vehicle_name)="row">
                        {{ row.item.vehicle ? row.item.vehicle.name : 'N/A' }}
                    </template>
                    <template #cell(vehicle_type)="row">
                        {{ row.item.vehicle ? row.item.vehicle.vehicle_type : 'N/A' }}
                    </template>
                    <template #cell(company_name)="row">
                        {{ row.item.company_name ? row.item.company_name : 'N/A' }}
                    </template>
                    <template #cell(uic)="row">
                        {{ row.item.uic ? row.item.uic : 'N/A' }}
                    </template>

                    <template #cell(vin)="row">
                        {{ row.item.vin ||'N/A' }}
                    </template>

                    <template #cell(color)="row">
                        {{ row.item.color ||'N/A' }}
                    </template>

                    <template #cell(license_plate)="row">
                        {{ row.item.license_plate ||'N/A' }}
                    </template>

                    <template #cell(registration_number)="row">
                        {{ row.item.registration_number ||'N/A' }}
                    </template>

                    <template #cell(status)="row">
                        <span :style="{color:row.item.status ? 'red' : 'black'}">
                        {{ row.item.status ||'N/A' }}
                        </span>
                    </template>

                    <template #cell(types)="row">
                        <span :style="{color:row.item.status ? 'green':'black'}">   
                        {{ row.item.types ||'N/A' }}
                        </span>
                    </template>


                    <template #cell(title)="row">
                        {{ row.item.model?.title ||'N/A' }}
                    </template>

                    <template #cell(name)="row">
                        {{ row.item.brand?.name ||'N/A' }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                    {{ formatDate(data.item.created_at) }}
                    </template>
                    <template v-slot:cell(created_at_time)="data">
                    {{ formatTime(data.item.created_at) }}
                    </template>

                    <template #cell(actions)="row">
                     
                        <!-- <b-button @click="seeTrack(row.item.id)" variant="primary" class="mb-8 mr-8">See
                            Tracking
                        </b-button> -->
                        <b-button @click="seeTrack(row.item.user_id, row.item.id)" variant="primary" class="mb-8 mr-8">
                        See Tracking
                        </b-button>

                    </template>


                    <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Type to Search"></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-table>
                <div class="mx-8 d-flex justify-content-end">
                    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
                        aria-controls="dataTable" @change="pageChanged"></b-pagination>
                </div>
                <b-row class="mt-16 align-items-center justify-content-end">
                    <b-row>
                    </b-row>
                </b-row>
            </div>
        </b-row>
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import Papa from "papaparse";

export default {
    // props: ['notification'],
    data() {
        return {
            filter: "",
            total: 0,
            perPage: 10,
            currentPage: 1,
            lastPage: 0,
            firstPageUrl: null,
            lastPageUrl: null,
            nextPageUrl: null,
            prevPageUrl: null,
            isLoading: false,
            sortBy: "",
            sortDesc: false,
            selectedCardOption: "",
            rowToUpdate: null,
            modalVisible: false,
            modalVisibleDoument: false,
            editMode: false,
            users: [], 
            fields: [
                { key: "id", sortable: true },
                { key: "company_name", sortable: true },
                { key: "model.title",label:"Model", sortable: true },
                { key: "color", sortable: true },
                { key: "license_plate", sortable: true },
                { key: "brand.name",label:"Brand", sortable: true },
                { key: "registration_number", sortable: true },
                { key: "vin", label:"Vehicle Identification Number", sortable: true },
                { key: "uic", label:"Unique Identification Code", sortable: true },
                { key: "year", sortable: true },
                { key: "types", sortable: true },
                { key: "created_at", label: "Date", formatter: this.formatDate },
                { key: "created_at_time", label: "Time" },
                { key: "status", sortable: true },
                { key: "actions", label: "Actions" },
            ],

            showDeleteConfirmations: false,
            itemIdToDelete: null,
            loading: false,
            startDateFilter: null,
            endDateFilter: null,
            ended_date: "",
            selected_date: "",
            your_vehicle_id: null,
            company_name: "",
            model: "",
            color: "",
            license_plate: "",
            make: "",
            types: "",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            status: "",
            showModal: false,
            selectedVehicleId: null,
            type: "", 
            userId:"",
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BTable,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BFormSelect,
        BPagination,
        BInputGroupAppend,
        BSpinner,
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
        rows() {
            return this.users.length;
        },
    },
    
    mounted() {
        this.fetchData();
        this.preFillVin();  
    },
    methods: {    
        preFillVin() {
            const vin = this.$route.params.vin;
            if (vin) {
                this.filter = vin; 
            }
        },
        clearFilter() {
            this.filter = '';     
            this.currentPage = 1;   
            this.fetchData();       
        },
        showTheftModal(vehicleId) {
      this.selectedVehicleId = vehicleId;
      this.showModal = true;
    },
  

        async fetchData() {
            this.loading = true;
            let apiUrl = "AllStolenVehicleindexForPolice";
            try {
                const response = await axios.get(apiUrl, {
                    params: { page: this.currentPage, per_page: this.perPage,
                        ended_date: this.endDateFilter,
                        selected_date: this.startDateFilter,
                     }
                });

                this.users = response.data.data;
                this.total = response.data.total;
                this.currentPage = response.data.current_page;
                this.perPage = response.data.per_page;
                this.lastPage = response.data.last_page;
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },

        pageChanged(newPage) {
            this.currentPage = newPage;
            this.fetchData();
        },
        // import  code 
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file && file.type === "text/csv") {
                this.fileToUpload = file;
            } else {
                alert("Please select a valid CSV file.");
            }
        },
    
        applyFilter() {
            this.loading = true;
            let apiUrl = "AllStolenVehicleindexForPolice"; 
            axios
                .get(apiUrl, {
                    params: {
                        search: this.filter,
                    },
                })
                .then((response) => {
                    this.users = response.data.data;
                    this.total = response.data.total;
                    this.currentPage = response.data.current_page;
                })
                .catch((error) => {
    
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openModal() {
            this.resetFormFields();

            this.modalVisible = true;
        },
        uploadDocuments() {
            this.resetUploadDocument();

            this.modalVisibleDoument = true;
        },
      
        onFiltered(filteredItems) {
            this.total = filteredItems.length;
            this.currentPage = 1;
        },
        uploadDocuments(studentId) {
            this.resetUploadDocument();
            this.studentId = studentId; 
            this.modalVisibleDoument = true;
        },
        resetUploadDocument() {
            this.documents = "";
            this.studentId = null; 
        },
       
        codeClick() {
            this.codeActive = !this.codeActive;
        },
        exportAllDataToCSV() {
            this.fetchAllParents((allParents) => {
                const csv = Papa.unparse(allParents);
                const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "exported_data.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        fetchAllParents(callback) {
            axios
                .get("AllStolenVehicleindexExportForPolice", { params: { per_page: this.totalRows } })
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
        
                });
        },

        downloadFile(fileUrl) {
            const link = document.createElement("a");
            link.href = "https://boltapi.fastnetstaffing.in/" + fileUrl;
            link.download = "downloaded_file"; 
            link.target = "_blank";
            link.click();
        },

        handleButtonClick(item) {
            this.updateStatus(item);
            this.toggleCardModal(item);
        },
        updateStatus(user) {
            user.status = user.status === "Approved" ? "Pending" : "Approved";
            axios
                .post(`registrationUpdate/${user.id}`, user)
                .then((response) => {
                
                })
                .catch((error) => {
              
                });
        },
        toggleCardModal(item) {
            this.selectedCardOption = ""; 
            this.rowToUpdate = item; 
            this.isCardModalVisible = true; 
        },
    
            formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
    }, 

        formatTime(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); 
    },
    changeDate() {
            const userId = this.$route.params.id;
            this.fetchData(userId);
        },
    
    async seeTrack(userId, vehicleId) {
    try {
        const response = await axios.get(`/vehicle-location/${userId}/${vehicleId}`);
        if (response.data.success) {
            // If the API call is successful, navigate to the tracking page
            this.$router.push({
                name: 'LiveTrackingStolenPolice',
                params: {
                    userId: userId,
                    vehicleId: vehicleId
                }
            });
        } else {
            console.error('Error: Vehicle not found');
            // Show a notification to the user
            this.$bvToast.toast('Vehicle not found', {
                title: 'Error',
                variant: 'primary',
                solid: true
            });
        }
    } catch (error) {
        console.error('Vehicle not found:', error);
        // Show a notification to the user in case of an API call failure
        this.$bvToast.toast('Vehicle not found', {
            title: 'Error',
            variant: 'primary',
            solid: true
        });
    }
}


                    
    },

    
};
</script>
