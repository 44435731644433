<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                MarketPlace Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Category:" label-for="category_id">
                                    <b-form-select id="category_id" placeholder="Select category id" v-model="category_id" :options="categories" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Model:" label-for="brand_model_id">
                                    <b-form-select id="brand_model_id" placeholder="Select brand model" v-model="brand_model_id" :options="brandModels" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Name:" label-for="brand_id">
                                    <b-form-select id="brand_id" placeholder="Select brand id" v-model="brand_id" :options="brands" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Year:" label-for="year">
                                    <b-form-input id="year" placeholder="Enter year"
                                        v-model="year" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration In:" label-for="registration_in">
                                    <b-form-input id="registration_in" placeholder="Enter registyration  in"
                                        v-model="registration_in" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number">
                                    <b-form-input id="registration_number" placeholder="Enter registration  number"
                                        v-model="registration_number" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number:" label-for="engine_number">
                                    <b-form-input id="engine_number" placeholder="Enter engine nnumber"
                                        v-model="engine_number" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter color"
                                        v-model="color" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city"
                                        v-model="city" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Area:" label-for="area">
                                    <b-form-input id="area" placeholder="Enter area"
                                        v-model="area" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country"
                                        v-model="country" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Mileage:" label-for="mileage">
                                    <b-form-input id="mileage" placeholder="Enter mileage"
                                        v-model="mileage" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Price:" label-for="price">
                                    <b-form-input id="price" placeholder="Enter price"
                                        v-model="price" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Kilometer Second Driven:" label-for="KMs_driven">
                                    <b-form-input id="KMs_driven" placeholder="Enter kilometer second"
                                        v-model="KMs_driven" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Fuel Type:" label-for="fuel_type">
                                    <b-form-input id="fuel_type" placeholder="Enter fuel type"
                                        v-model="fuel_type" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group label="Transmission Type:" label-for="transmission_type">
                                <b-form-select id="transmission_type" v-model="transmission_type" :options="statusOptions" required>
                                </b-form-select>
                            </b-form-group>
                            </div>

                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Transmission Type:" label-for="transmission_type">
                                    <b-form-input id="transmission_type" placeholder="Enter transmission type"
                                        v-model="transmission_type" required>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Capacity:" label-for="engine_capacity">
                                    <b-form-input id="engine_capacity" placeholder="Enter engine capacity"
                                        v-model="engine_capacity" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Features:" label-for="features">
                                    <b-form-input id="features" placeholder="Enter features"
                                        v-model="features" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Assemble:" label-for="assembly">
                                    <b-form-input id="assembly" placeholder="Enter assembly"
                                        v-model="assembly" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Satus:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status"
                                        v-model="status" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <!-- Document   code  here  -->
                          
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Vehicle Documents:" label-for="images">
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                @change="onDocumentChange"
                            />
                            <div v-if="documentPreviews.length" style="margin-left: 3px; margin-bottom: 15px">
                                <img
                                    v-for="(doc, index) in documentPreviews"
                                    :key="index"
                                    :src="doc"
                                    alt="Vehicle Document"
                                    width="100"
                                    height="100"
                                    style="margin-right: 10px"
                                />
                                
                            </div>
                            <div v-else>
                            <span>No document available</span>
                            </div>
                        </b-form-group>
                    </div>



                        </div>

                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
          
            year: "",
            brand_model_id: '',
            category_id: '',
            brandModels:[],
            brand_id: '',
            brands: [], 
            registration_in:"",
            registration_number: '',
            engine_number: '',
            color: '',
            city: '',
            area: '',
            country: '',
            mileage: '',
            price: '',
            KMs_driven: '',
            fuel_type: '',
            transmission_type: '',
            engine_capacity: '',
            description: '',
            features: '',
            assembly: '',
            status: '',
            // category:"",
            user_id:"",
            document_id:'',
            documents: [], 
            categories: [], 
            // documentFiles: [],
            // documentPreviews: [],
            documentFiles: [],  // Actual files selected for upload
            documentPreviews: [],
            editedUser: {},
            image: [], 
            imageFiles: [], 
            imagePreviews: [],
            statusOptions: [
            // { value: null, text: 'Select Transmission Type' },
            { value: 'manual', text: 'Manual' },
            { value: 'auto', text: 'Auto' }
            ],


        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },
    mounted() {
                axios.get('brand')
                    .then(response => {
                        this.brands = response.data.data.map(brand => ({
                            value: brand.id,  
                            text: brand.name  
                        }));

                    })
                    .catch(error => {
                    });

                    axios.get('CategoryList')
                    .then(response => {
                        this.categories = response.data.data.map(category => ({
                            value: category.id,  
                            text: category.title  
                        }));

                    })
                    .catch(error => {
                    });
                    axios.get('brandModel')
                        .then(response => {
    
                            this.brandModels = response.data.data.map(model => ({
                                value: model.id,  
                                text: model.title 
                            }));
            
                        })
                        .catch(error => {
                        });
            },  
 
    created() {
    const userId = this.$route.params.id;
    axios
        .get(`marketPlaceShow/${userId}`)
        .then((response) => {
            this.editedUser = response.data.data;
            this.year = this.editedUser.year ||'';
            this.brand_model_id = this.editedUser.brand_model_id||'';
            this.brand_id = this.editedUser.brand_id ||'';
            this.category_id = this.editedUser.category_id ||'';
            this.registration_number = this.editedUser.registration_number ||'';
            this.engine_number = this.editedUser.engine_number ||'';
            this.registration_in = this.editedUser.registration_in ||'';
            this.color = this.editedUser.color ||'';
            this.city = this.editedUser.city ||'';
            this.area = this.editedUser.area ||'';
            this.country = this.editedUser.country ||'';
            this.mileage = this.editedUser.mileage ||'';
            this.price = this.editedUser.price ||'';
            this.KMs_driven = this.editedUser.KMs_driven ||'';
            this.fuel_type = this.editedUser.fuel_type ||'';
            this.transmission_type = this.editedUser.transmission_type ||'';
            this.engine_capacity = this.editedUser.engine_capacity ||'';
            this.description = this.editedUser.description ||'';
            this.features = this.editedUser.features ||'';
            this.assembly = this.editedUser.assembly ||'';
            this.status = this.editedUser.status ||'';
            this.image = this.editedUser.image ||'';
            this.category = this.editedUser.category ||'';
            this.user_id = this.editedUser.user_id;
            this.document_id = this.editedUser.document_id;
            this.documentPreviews = this.editedUser.documents.map(
                doc => `https://wossdb.wossautoid.com/${doc.documents}`
            );
        })
        .catch((error) => {
            console.error("Error fetching user data:", error);
        });
},
   

    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file

            const formData = new FormData();
            formData.append("year", this.year);
            formData.append("brand_model_id", this.brand_model_id);
            formData.append("category_id", this.category_id);
            formData.append("brand_id", this.brand_id);
            formData.append("registration_number", this.registration_number);
            formData.append("engine_number", this.engine_number);
            formData.append("registration_in", this.registration_in);
            formData.append("color", this.color);
            formData.append("city", this.city);
            formData.append("area", this.area);
            formData.append("country", this.country);
            formData.append("mileage", this.mileage);
            formData.append("price", this.price);
            formData.append("KMs_driven", this.KMs_driven);
            formData.append("fuel_type", this.fuel_type);
            formData.append("transmission_type", this.transmission_type);
            formData.append("engine_capacity", this.engine_capacity);
            formData.append("description", this.description);
            formData.append("features", this.features);
            formData.append("assembly", this.assembly);
            formData.append("status", this.status);
            formData.append("user_id", 1);     
             // Append document files
            //  this.documentFiles.forEach((file, index) => {
            //     formData.append(`documents[${index}]`, file);
            // });
            // Append document files
    if (this.documentFiles.length > 0) {
        this.documentFiles.forEach((file, index) => {
            formData.append(`image[${index}]`, file);
        });
    }
          

            axios
                .post(`marketPlaceUpdate/${this.editedUser.id}`, formData)
                .then((response) => {
                    this.$bvToast.toast("Marketplace Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", // Background color
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListMarketPlace' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.isLoading = false;
                });
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },

        onDocumentChange(event) {
        this.documentFiles = Array.from(event.target.files); // Store the selected files
        this.documentPreviews = this.documentFiles.map(file => URL.createObjectURL(file)); // Show preview
    },
       
    },
};
</script>