<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Product Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Title:" label-for="name">
                                    <b-form-input id="name" placeholder="Enter name"
                                        v-model="name" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Amount:" label-for="price">
                                    <b-form-input id="price" placeholder="Enter price"
                                        v-model="price" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Quantity:" label-for="quantity">
                                    <b-form-input id="quantity" placeholder="Enter quantity"
                                        v-model="quantity" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            
                        <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Images:" label-for="image">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <input type="file" accept="image/*" id="image" multiple @change="onProfilePictureChange" />
                                </div>
                            </b-form-group>
                        </div>
                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
  data() {
    return {
      show: true,
      codeText: code.introduction,
      codeActive: false,
      codeActiveClass: false,
      showModal: false,
      isLoading: false,
      description: "",
      name: "",
      price: "",
      quantity:"",
      image: [],
      successMessage: "",
      errorMessage: "",
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BToast,
    BSpinner,
  },
  methods: {

    addUser() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("price", this.price);
      formData.append("description", this.description);
      formData.append("quantity", this.quantity);
      if (this.image && this.image.length > 0) {
                this.image.forEach((file, index) => {
                    formData.append(`image[${index}]`, file);
                });
            }
      axios
        .post("ProductStore", formData)
        .then((response) => {
          this.$bvToast.toast("Product added successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 5000,
            variant: "primary",
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoading = false;
        });
    },
    onProfilePictureChange(event) {
                const files = event.target.files;

                if (files && files.length > 0) {
                    if (files.length > 10) {
                    this.$bvToast.toast("You can only upload up to 3 images.", {
                        title: "Error",
                        variant: "danger",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary",
                    });
                    this.image = Array.from(files).slice(0, 10);
                    } else {

                    this.image = Array.from(files);
                    }
                }
                },
  },
};
</script>
