<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
          <div class="col-12 mt-16">
            <b-form @submit.prevent="addUser" v-if="show">
              <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                  Model Information
                </h4>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-1" label="Model Name:" label-for="title">
                    <b-form-input id="title" type="text" placeholder="Enter name" autocomplete="off"
                      v-model="title" disabled>
                    </b-form-input>
                  </b-form-group>
                </div>
  
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Brand Name:" label-for="brand_id">
                    <b-form-input id="brand_id" :value="selectedBrandName" disabled>
                    </b-form-input>
                  </b-form-group>
                </div>
  
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Model Picture:" label-for="image">
                    <div style="margin-left: 3px; margin-bottom: 15px">
                      <div v-if="image">
                      <img :src="'https://wossdb.wossautoid.com/' + image"
                        alt="Model Picture" width="100" height="100" />
                      </div>
                      <div v-else>
                      <span>No image available</span>
                    </div>
                    </div>
                    
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </b-row>
      </b-card>
    </div>
  </template>
  

  <script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios"; 
import { BToast } from "bootstrap-vue";

export default {
  data() {
    return {
      show: true,
      title: "",
      image: "",
      brand_id: "", 
      brands: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BToast,
    BSpinner,
  },
  created() {
    const userId = this.$route.params.id;
    axios
      .get(`brandModelShow/${userId}`)
      .then((response) => {
        this.editedUser = response.data.data;
        this.title = this.editedUser.title ||'';
        this.brand_id = this.editedUser.brand_id ||'';
        this.image = this.editedUser.image ||'';
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },
  mounted() {
    axios.get('brand')
      .then(response => {
        // Map the API response to the format required by b-form-select
        this.brands = response.data.data.map(brand => ({
          value: brand.id,  
          text: brand.name  
        }));

      })
      .catch(error => {
      });
  },
  computed: {
    selectedBrandName() {
      // Find the brand name based on brand_id
      const selectedBrand = this.brands.find(brand => brand.value === this.brand_id);
      return selectedBrand ? selectedBrand.text : '';
    }
  },
  methods: {
    showMsgBoxOne() {
      debugger;
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onProfilePictureChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;
      }
    },
    codeClick() {
      this.codeActive = !this.codeActive;
    },
    saveOwnCar() {
      this.showModal = false;
    },
  },
};
</script>
