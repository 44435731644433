<template>
    <b-card>
        <!-- filter  -->
        <div v-if="loading" class="text-center mt-4">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="col-12 mt-16">
            <div>
                <b-row class="align-items-center">
                    <b-col lg="4" class="my-1">
                        <b-form-group label="" label-for="filter-input" label-cols-sm="1" label-align-sm="right"
                            label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search"
                                    placeholder="Type to Search"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="applyFilter" :disabled="!filter"
                                        variant="primary">Apply</b-button>
                                    <b-button :disabled="!filter" @click="clearFilter">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                       <!-- .. Filter  for   date-->
                       <b-col lg="3" class="my-1">
                    <b-form-input id="start-date" v-model="startDateFilter" type="date"
                        placeholder="Select start date"></b-form-input>
                </b-col>
                <b-col lg="3" class="my-1">
                    <b-form-input id="end-date" v-model="endDateFilter" type="date" placeholder="Select end date"
                        @change="changeDate"></b-form-input>
                </b-col>
                    <!-- Filter  for   date-  -->
                    <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv"
                        style="display: none;" />
                    <b-col lg="2" class="my-1 d-flex justify-content-end">
                        <b-button @click="exportAllDataToCSV" variant="primary" class="mb-8 mr-8">Export</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
     

        <!-- filter end -->
        <b-row>
            <div class="col-12 mt-16">
                <b-table id="dataTable" :items="users" :busy.sync="loading" :fields="fields" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty responsive>
                    <template #cell(actions)="row">
                        <b-button @click="showParent(row.item.id)" variant="link" class="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" style="
                color: rgba(0, 255, 195, 0.87);
                margin-left: 6px;
                margin-bottom: 10px;
              " class="bi bi-eye" viewBox="0 0 16 16">
                                <path
                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path
                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                        </b-button>
                      
                    </template>
                    <template v-slot:cell(updated_at)="data">
                    {{ formatDate(data.item.updated_at) }}
                    </template>
                    <template v-slot:cell(updated_at_time)="data">
                    {{ formatTime(data.item.updated_at) }}
                    </template>
                    <template #cell(company_name)="row">
                        {{ row.item.company_name ? row.item.company_name : 'N/A ' }}
                    </template>
                    
                    <template #cell(uic)="row">
                    {{ row.item.uic ? row.item.uic : 'N/A' }}
                    </template>

                    <template #cell(vin)="row">
                        {{ row.item.vin ||'N/A' }}
                    </template>

                    <template #cell(year)="row">
                        {{ row.item.year ||'N/A' }}
                    </template>

                    <template #cell(color)="row">
                        {{ row.item.color ||'N/A' }}
                    </template>

                    <template #cell(license_plate)="row">
                        {{ row.item.license_plate ||'N/A' }}
                    </template>

                    <template #cell(registration_number)="row">
                        {{ row.item.registration_number ||'N/A' }}
                    </template>

                    <template #cell(status)="row">
                        <span :style="{color:row.item.status ? 'red' : 'black'}">
                        {{ row.item.status ||'N/A' }}
                    </span>
                    </template>

                    <template #cell(types)="row">
                        <span :style="{color:row.item.types ? 'green':'black'}">
                        {{ row.item.types ||'N/A' }}
                    </span>
                    </template>

                    <template #cell(title)="row">
                        {{ row.item.model?.title ||'N/A' }}
                    </template>

                    <template #cell(name)="row">
                        {{ row.item.brand?.name ||'N/A' }}
                    </template>
                    <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Type to Search"></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-table>
                <div class="mx-8 d-flex justify-content-end">
                    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
                        aria-controls="dataTable" @change="pageChanged"></b-pagination>
                </div>
                <b-row class="mt-16 align-items-center justify-content-end">
                    <b-row>
                    </b-row>
                </b-row>
            </div>
        </b-row>
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import Papa from "papaparse";

export default {
    data() {
        return {
            filter: "",
            total: 0,
            perPage: 10,
            currentPage: 1,
            lastPage: 0,
            firstPageUrl: null,
            lastPageUrl: null,
            nextPageUrl: null,
            prevPageUrl: null,
            isLoading: false,
            sortBy: "",
            sortDesc: false,
            selectedCardOption: "",
            rowToUpdate: null,
            modalVisible: false,
            modalVisibleDoument: false,
            editMode: false,
            users: [],
            fields: [
                { key: "id", sortable: true },
                { key: "company_name", sortable: true },
                { key: "model.title",label:"Model", sortable: true },
                { key: "color", sortable: true },
                { key: "license_plate", sortable: true },
                { key: "brand.name",label:"Brand", sortable: true },
                { key: "registration_number", sortable: true },
                { key: "vin", label:"Vehicle Identification Number", sortable: true },
                { key: "uic", label:"Unique Identification Code", sortable: true },
                { key: "year", sortable: true },
                { key: "types", sortable: true },
                { key: "updated_at", label: "Date", formatter: this.formatDate },
                { key: "updated_at_time", label: "Time" },
                { key: "status", sortable: true },
                { key: "actions", label: "Actions" },
            ],

            showDeleteConfirmations: false,
            itemIdToDelete: null,
            loading: false,
            startDateFilter: null,
            endDateFilter: null,
            ended_date: "",
            selected_date: "",
            your_vehicle_id: null,
            company_name: "",
            model: "",
            color: "",
            license_plate: "",
            make: "",
            types: "",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            status: "",
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BTable,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BFormSelect,
        BPagination,
        BInputGroupAppend,
        BSpinner,
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
        rows() {
            return this.users.length;
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        clearFilter() {
            this.filter = '';     
            this.currentPage = 1;   
            this.fetchData();       
        },
        async fetchData() {
            this.loading = true;
            let apiUrl = "AllRecoverVehicleindexForPolice";
            try {
                const response = await axios.get(apiUrl, {
                    params: { page: this.currentPage, per_page: this.perPage,
                        ended_date: this.endDateFilter,
                        selected_date: this.startDateFilter,
                     }
                });

                this.users = response.data.data;
                this.total = response.data.total;
                this.currentPage = response.data.current_page;
                this.perPage = response.data.per_page;
                this.lastPage = response.data.last_page;
            } catch (error) {
            
            } finally {
                this.loading = false;
            }
        },

        pageChanged(newPage) {
            this.currentPage = newPage;
            this.fetchData();
        },
    
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file && file.type === "text/csv") {
                this.fileToUpload = file;
                this.importStudents();
            } else {
                alert("Please select a valid CSV file.");
            }
        },
        async importStudents() {
            const formData = new FormData();
            formData.append('file', this.fileToUpload);

            this.loading = true; // start loading

            try {
                const response = await axios.post('importParents', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                this.$bvToast.toast("File uploaded successfully.", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                    appendToast: true,
                    toaster: "b-toaster-top-right",
                    autoHideDelay: 5000,
                    variant: "primary",
                });
            } catch (error) {
            
                this.$bvToast.toast("Failed to upload CSV file.", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                    appendToast: true,
                    toaster: "b-toaster-top-right",
                    autoHideDelay: 5000,
                });
            } finally {
                this.loading = false; 
            }
        },
        
        applyFilter() {
            this.loading = true;
            let apiUrl = "AllRecoverVehicleindexForPolice";
            axios
                .get(apiUrl, {
                    params: {
                        search: this.filter,
                    },
                })
                .then((response) => {
                    this.users = response.data.data;
                    this.total = response.data.total;
                    this.currentPage = response.data.current_page;
                })
                .catch((error) => {
                    console.error("Error applying filter:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openModal() {
            this.resetFormFields();

            this.modalVisible = true;
        },
        uploadDocuments() {
            this.resetUploadDocument();

            this.modalVisibleDoument = true;
        },
      
        onFiltered(filteredItems) {
            this.total = filteredItems.length;
            this.currentPage = 1;
        },
        uploadDocuments(studentId) {
            this.resetUploadDocument();
            this.studentId = studentId; 
            this.modalVisibleDoument = true;
        },
        resetUploadDocument() {
            this.documents = "";
            this.studentId = null; 
        },
       
        codeClick() {
            this.codeActive = !this.codeActive;
        },
        exportAllDataToCSV() {
            this.fetchAllParents((allParents) => {
                const csv = Papa.unparse(allParents);
                const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "exported_data.csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        fetchAllParents(callback) {
            axios
                .get("AllRecoverVehicleindexExportForPolice", { params: { per_page: this.totalRows } })
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                  
                });
        },
    
        showParent(userId) {
            this.$router.push({ name: "RecoverVehicleForPolice", params: { id: userId } });
        },
        downloadFile(fileUrl) {
            const link = document.createElement("a");
            link.href = "https://boltapi.fastnetstaffing.in/" + fileUrl;
            link.download = "downloaded_file"; 
            link.target = "_blank";
            link.click();
        },
            // Display  date  code  here 
            formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
    }, 
        // Display  time  code  here 
        formatTime(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); 
    },
    changeDate() {
            const userId = this.$route.params.id;
            this.fetchData(userId);
        },
    },


};
</script>
