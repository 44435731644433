<template>
    <b-card>
        <!-- filter  -->
        <div v-if="loading" class="text-center mt-4">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="col-12 mt-16">
            <div>
                <b-row class="align-items-center">
                    <b-col lg="6" class="my-1">
                        <b-form-group label="" label-for="filter-input" label-cols-sm="1" label-align-sm="right"
                            label-size="sm" class="mb-0">
                            <b-input-group size="sm">
                                <b-form-input id="filter-input" v-model="filter" type="search"
                                    placeholder="Type to Search"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="applyFilter" :disabled="!filter"
                                        variant="primary">Apply</b-button>
                                <b-button :disabled="!filter" @click="clearFilter">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" class="my-1 d-flex justify-content-end">
                        <b-button @click="exportAllDataToCSV" variant="primary" class="mb-8 mr-8">Export</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <!-- filter end -->
        <b-row>
            <div class="col-12 mt-16">
                <b-table id="dataTable" :items="users" :busy.sync="loading" :fields="fields" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty responsive>
                   
                    <template #cell(first_name)="row">
                        {{ row.item.user?.first_name ||'N/A' }}
                    </template>

                    <template #cell(last_name)="row">
                        {{ row.item.user?.last_name ||'N/A' }}
                    </template>

                    <template #cell(profession)="row">
                        {{ row.item.user?.profession ||'N/A' }}
                    </template>

                    <template #cell(street_address)="row">
                        {{ row.item.user?.street_address ||'N/A' }}
                    </template>

                    <template #cell(city)="row">
                        {{ row.item.user?.city ||'N/A' }}
                    </template>

                    <template #cell(country)="row">
                        {{ row.item.user?.country ||'N/A' }}
                    </template>

                    <template #cell(postcode)="row">
                        {{ row.item.user?.postcode ||'N/A' }}
                    </template>

                    <template #cell(email)="row">
                        {{ row.item.user?.email ||'N/A' }}
                    </template>

                    <template #cell(type)="row">
                        {{ row.item.user?.type ||'N/A' }}
                    </template>

                    <template #cell(actions)="row">
                        <b-button @click="showDrivers(row.item.id)" variant="link" class="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" style="
                color: rgba(0, 255, 195, 0.87);
                margin-left: 6px;
                margin-bottom: 10px;
              " class="bi bi-eye" viewBox="0 0 16 16">
                                <path
                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path
                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                        </b-button>
                        <!-- delete -->
                        <b-button @click="showDeleteConfirmation(row.item.id)" variant="link" class="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                style="color: red; margin-left: 6px; margin-bottom: 10px" class="bi bi-eye"
                                viewBox="0 0 16 16">
                                <!-- ... your SVG path ... -->
                                <path
                                    d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                        </b-button>
                        <b-modal v-model="showDeleteConfirmations" title="Delete Confirmation">
                            <p>Are you sure you want to delete this item?</p>
                            <template #modal-footer>
                                <b-button variant="danger" @click="deleteItem(itemIdToDelete)">Delete</b-button>
                                <b-button variant="secondary" @click="showDeleteConfirmations = false">Cancel</b-button>

                            </template>
                        </b-modal>
                        <!-- end delete -->

                    </template>
                                  <!-- image  show code here  -->
                                  <template #cell(image)="row">
                        <img :src="'https://wossdb.wossautoid.com/' + row.item.image" alt="Picture" width="100"
                            height="100" />
                    </template>

                    <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                        label-size="sm" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Type to Search"></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-table>
                <div class="mx-8 d-flex justify-content-end">
                    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
                        aria-controls="dataTable" @change="pageChanged"></b-pagination>
                </div>
                <b-row class="mt-16 align-items-center justify-content-end">

                    <b-row>

                    </b-row>
                </b-row>
            </div>
        </b-row>
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BSpinner,
} 
from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import Papa from "papaparse";

export default {
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            lastPage: 0,
            firstPageUrl: null,
            lastPageUrl: null,
            nextPageUrl: null,
            prevPageUrl: null,
            isLoading: false,
            fields: [
                { key: "id", sortable: true },
                { key: "user.first_name",label:"First Name", sortable: true },
                { key: "user.last_name",label:"Last Name", sortable: true },
                { key: "user.email",label:"Email", sortable: true },
                { key: "user.phone_number",label:"Phone Number", sortable: true },
                { key: "user.city", label:"City", sortable: true },
                { key: "user.country",label:"Country", sortable: true },
                { key: "user.postcode",label:"Post Code", sortable: true },
                { key: "user.profession",label:"Profession", sortable: true },
                { key: "user.street_address",label:"Address", sortable: true },
                { key: "user.type",label:"Types", sortable: true },
                { key: "actions", label: "Actions" },
            ],
            filter: "", 
            total: 0, 
            showDeleteConfirmations: false,
            itemIdToDelete: null,
            loading: false,
            image: "",
        };

    },

    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BTable,
        BFormGroup,
        BInputGroup,
        BFormInput,
        BFormSelect,
        BPagination,
        BInputGroupAppend,
        BSpinner,

    },
    computed: {
        sortOptions() {
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return { text: f.label, value: f.key };
                });
        },
        rows() {
            return this.users.length;
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        clearFilter() {
            this.filter = '';     
            this.currentPage = 1;   
            this.fetchData();       
        },
        async fetchData() {
            this.loading = true;
            let apiUrl = "AllTransferVehicleindex";
            try {
                const response = await axios.get(apiUrl, {
                page: this.currentPage, per_page: this.perPage 
                });

                this.users = response.data.data;
                this.total = response.data.total;
                this.currentPage = response.data.current_page;
                this.perPage = response.data.per_page;
                this.lastPage = response.data.last_page;
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.loading = false;
            }
        },
        applyFilter() {
            this.loading = true;
            let apiUrl = "AllTransferVehicleindex"; 
            axios
                .get(apiUrl, {
                        search: this.filter,
                })
                .then((response) => {
                    this.users = response.data.data;
                    this.total = response.data.total;
                    this.currentPage = response.data.current_page;
                })
                .catch((error) => {
                    console.error("Error applying filter:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        pageChanged(newPage) {
            this.currentPage = newPage;
            this.fetchData();
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        codeClick() {
            this.codeActive = !this.codeActive;
        },
        // exportAllDataToCSV() {
        //     this.fetchAllParents((allParents) => {
        //         const csv = Papa.unparse(allParents);
        //         const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        //         const link = document.createElement("a");
        //         link.href = URL.createObjectURL(blob);
        //         link.setAttribute("download", "exported_data.csv");
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     });
        // },
        // fetchAllParents(callback) {
        //     axios
        //         .get("AllTransferVehicleindexExport", { params: { per_page: this.totalRows } })
        //         .then((response) => {
        //             callback(response.data.data);
        //         })
        //         .catch((error) => {
        //             console.error("Error fetching all Data:", error);
        //         });
        // },

        exportAllDataToCSV() {
    this.fetchAllParents((allParents) => {
        // Flatten the data
        const flattenedData = allParents.map((item) => ({
            id: item.id,
            user_id: item.user_id,
            vin: item.vin,
            uic: item.uic,
            brand: item.brand.name, 
            model: item.model.title, 
            year: item.year,
            color: item.color,
            license_plate: item.license_plate,
            engine_number: item.engine_number,
            registration_number: item.registration_number,
            company_name: item.company_name,
            status: item.status,
            description: item.description,
            features: item.features,
            transfer_status: item.transfer_status,
            user_first_name: item.user.first_name, 
            user_last_name: item.user.last_name,
            user_email: item.user.email,
            user_country: item.user.country,
            user_rfc: item.user.rfc,
            user_trade_name: item.user.trade_name,
            user_office_phone: item.user.office_phone,
            old_user_id: item.old_user_id,
            old_user_first_name: item.old_onwer?.first_name,
            old_user_last_name: item.old_onwer?.last_name,
            old_user_email: item.old_onwer?.email,
            old_user_phone_number: item.old_onwer?.phone_number,
            old_user_country: item.old_onwer?.country,
            old_user_profession: item.old_onwer?.profession,
            old_user_street_addressr: item.old_onwer?.street_address,
            old_user_type: item.old_onwer?.type,
          
          
        }));

        // Generate CSV
        const csv = Papa.unparse(flattenedData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "exported_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
        },
        fetchAllParents(callback) {
            axios
                .get("AllTransferVehicleindexExport", { params: { per_page: this.totalRows } })
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching all Data:", error);
                });
        },
        showDrivers(userId) {
            this.$router.push({ name: "ViewOwnerShip", params: { id: userId } });
        },
        ///  delete
        showDeleteConfirmation(itemId) {
            this.itemIdToDelete = itemId;
            this.showDeleteConfirmations = true;
        },
        deleteItem(itemId) {

            this.itemIdToDelete = itemId; 
            axios
                .delete(`vehicleDelete/${itemId}`)
                .then((response) => {
                    this.showDeleteConfirmations = false;
                    this.fetchData(); // Refresh the data after deletion
                })
                .catch((error) => {
                    // Handle error
                    console.error("Error deleting item:", error);
                });


        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();

        },
    },
};
</script>