<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Personal Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" placeholder="Enter first name"
                                        v-model="first_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                             
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" placeholder="Enter last name"
                                        v-model="last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group label="Email:" label-for="email">
                                    <b-form-input id="email" placeholder="Enter email" v-model="email" disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Phone Number:" label-for="phone_number">
                                    <b-form-input id="phone_number" placeholder="Enter phone number"
                                        v-model="phone_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City: "
                                    label-for="city">
                                    <b-form-input id="city"
                                        placeholder="Enter city" v-model="city"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="State: "
                                    label-for="state">
                                    <b-form-input id="state"
                                        placeholder="Enter state" v-model="state" disabled
                                        ></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country: "
                                    label-for="country">
                                    <b-form-input id="country" 
                                        placeholder="Enter country" v-model="country"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Postcode: "
                                    label-for="postcode">
                                    <b-form-input id="postcode"
                                        placeholder="Enter registration number" v-model="postcode"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address: "
                                    label-for="street_address">
                                    <b-form-input id="street_address" 
                                        placeholder="Enter street address" v-model="street_address"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="User Type: "
                                    label-for="type">
                                    <b-form-input id="type" 
                                        placeholder="Enter type:" v-model="type"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                <b-form-input id="company_name" placeholder="Enter company name" :value="userCompanyName || ''" disabled></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registro Federal Contribuyente:" label-for="rfc">
                                    <b-form-input id="rfc" placeholder="Enter rfc"
                                       :value="rfc || ''" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Trade Name:" label-for="trade_name">
                                    <b-form-input id="trade_name" placeholder="Enter trade name"
                                       :value="trade_name || ''" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Office Number: "
                                    label-for="office_phone">
                                    <b-form-input id="office_phone" 
                                        placeholder="Enter office phone" :value="office_phone || ''"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="User Picture:" label-for="user-image">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <div v-if="image">   
                                    <img 
                                        :src="'https://wossdb.wossautoid.com/' + userImage"
                                        alt="User Picture" 
                                        width="100" 
                                        height="100" 
                                    />
                                </div>
                                        <div v-else>
                                        <span>No image available</span>
                                      </div>   
                                </div>
                            </b-form-group>
                        </div>

                      <!-- Document Upload Field -->

                    <div class="col-md-4 col-12">
                    <b-form-group id="input-group-2" label="Document:" label-for="documents">
                        <div style="margin-left: 3px; margin-bottom: 15px">
                        <!-- Check if documents is not null and not empty -->
                        <div v-if="document">
                            <a :href="'https://wossdb.wossautoid.com/' + documents" target="_blank">
                            <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                            </a>
                        </div>
                        <!-- If no document is available -->
                        <div v-else>
                            <span>No document available</span>
                        </div>
                        </div>
                    </b-form-group>
                    </div>


                        </div>
                        <!-- ..................  -->
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Vehicle Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                    <b-form-input id="company_name" placeholder="Enter company name"
                                        v-model="company_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group label="Brand Name:" label-for="make">
                                    <b-form-input id="brand" placeholder="Enter vehicle brand" v-model="brand" disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group label="Brand Model:" label-for="model">
                                    <b-form-input id="model" placeholder="Enter vehicle model" v-model="model" disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter vehicle color"
                                        v-model="color" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="License Plate: "
                                    label-for="license_plate">
                                    <b-form-input id="license_plate"
                                        placeholder="Enter license plate" v-model="license_plate"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number: "
                                    label-for="engine_number">
                                    <b-form-input id="engine_number"
                                        placeholder="Enter engine number" v-model="engine_number" disabled
                                        ></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Approve Status: "
                                    label-for="types">
                                    <b-form-input id="types" 
                                        placeholder="Enter types" v-model="types"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration  Number: "
                                    label-for="registration_number">
                                    <b-form-input id="registration_number"
                                        placeholder="Enter registration number" v-model="registration_number"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Year: "
                                    label-for="year">
                                    <b-form-input id="year" 
                                        placeholder="Enter vehicle year" v-model="year"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Identification Number: "
                                    label-for="vin">
                                    <b-form-input id="vin" 
                                        placeholder="Enter vehicle identification number:" v-model="vin"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Unique Identification Code:" label-for="uic">
                                <b-form-input id="uic" placeholder="Enter unique identification code" v-model="uic" disabled></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description"disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Features:" label-for="features">
                                    <b-form-input id="features" placeholder="Enter feature"
                                        v-model="features"disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Status: "
                                    label-for="status">
                                    <b-form-input id="status" 
                                        placeholder="Enter vehicle status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                         
                    <div class="col-md-4 col-12">
                    <b-form-group id="input-group-2" label="Vehicle Images:" label-for="images">
                        <div v-if="image && image.length" style="margin-left: 3px; margin-bottom: 15px">
                        <img
                            v-for="(image, index) in image"
                            :key="index"
                            :src="'https://wossdb.wossautoid.com/' + image"
                            alt="Vehicle Image"
                            width="100"
                            height="100"
                            style="margin-right: 10px"
                        />
                        </div>
                        <div v-else>
                        <span>No image available</span>
                        </div>  
                    </b-form-group>
                    </div>
                  
                      <!-- Document Upload Field -->

                <div class="col-md-4 col-12">
                <b-form-group id="input-group-3" label="Vehicle Documents:" label-for="documents">
                    <div v-if="documents && documents.length" style="margin-bottom: 15px;">
                        <div v-for="(doc, index) in documents" :key="index" style="margin-bottom: 10px;">
                            <span
                                style="cursor: pointer; color: blue;"
                                @click="openDocuments(baseUrl + doc.documents)"
                            >
                                <i class="fas fa-file-pdf" style="margin-right: 5px;"></i>
                                Document {{ index + 1 }}
                            </span>
                            <!-- <button
                                @click="downloadDocument(doc.documents)"
                                class="btn btn-primary btn-sm ml-2"
                            >
                                Download
                            </button> -->
                        </div>
                    </div>
                    <div v-else>
                        <p>No documents available</p>
                    </div>
                 </b-form-group>
                </div>


                        </div>
                        <!-- ................... OwnerShip -->
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                OwnerShip Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" placeholder="Enter first name"
                                        :value="oldOwnerFirstName" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                             
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" placeholder="Enter last name"
                                    :value="oldOwnerLastName" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group label="Email:" label-for="email">
                                    <b-form-input id="email" placeholder="Enter email" :value="oldOwnerEmail" disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Phone Number:" label-for="phone_number">
                                    <b-form-input id="phone_number" placeholder="Enter phone number"
                                        :value="oldOwnerPhoneNumber" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City: "
                                    label-for="city">
                                    <b-form-input id="city"
                                        placeholder="Enter city" :value="oldOwnerCity"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="State: "
                                    label-for="state">
                                    <b-form-input id="state"
                                        placeholder="Enter state" :value="oldOwnerState" disabled
                                        ></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country: "
                                    label-for="country">
                                    <b-form-input id="country" 
                                        placeholder="Enter country" :value="oldOwnerCountry"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Postcode: "
                                    label-for="postcode">
                                    <b-form-input id="postcode"
                                        placeholder="Enter registration number" :value="oldOwnerPostCode"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address: "
                                    label-for="street_address">
                                    <b-form-input id="street_address" 
                                        placeholder="Enter street address" :value="oldOwnerAddress"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="User Type: "
                                    label-for="type">
                                    <b-form-input id="type" 
                                        placeholder="Enter type:" :value="oldOwnerUserType"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                <b-form-input id="company_name" placeholder="Enter company name"  :value="oldOwnerCompanyName || ''" disabled></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registro Federal Contribuyente:" label-for="rfc">
                                    <b-form-input id="rfc" placeholder="Enter rfc"
                                    :value="oldOwnerRFC || ''"disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Trade Name:" label-for="trade_name">
                                    <b-form-input id="trade_name" placeholder="Enter trade name"
                                    :value="oldOwnerTradeName || ''"disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Office Number: "
                                    label-for="office_phone">
                                    <b-form-input id="office_phone" 
                                        placeholder="Enter office phone" :value="oldOwnerOfficePhone || ''"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                         
                 
                    <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Picture:" label-for="owner-image">
                                <div style="margin-left: 3px; margin-bottom: 15px">
                                    <div v-if="image">      
                                <img :src="'https://wossdb.wossautoid.com/' + ownerImage"
                                    alt="Picture" width="100" height="100" />
                                </div>
                                <div v-else>
                                <span>No image available</span>
                                </div>    
                                </div>
                            </b-form-group>
                            </div>
                      <!-- Document Upload Field -->
                    
                    <div class="col-md-4 col-12">
                    <b-form-group id="input-group-2" label="Document:" label-for="documents">
                        <div style="margin-left: 3px; margin-bottom: 15px">
                        <div v-if="documentss">
                            <a :href="'https://wossdb.wossautoid.com/' + documents" target="_blank">
                            <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                            </a>
                        </div>
                        <div v-else>
                            <span>No document available</span>
                        </div>
                        </div>
                    </b-form-group>
                    </div>


                        </div>
        
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            commission: "",
            selectedCarType: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            
            company_name: "",
            model: "",
            color: "",
            license_plate: "",
            brand: "",
            types: "",
            engine_number:"",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            status: "safe",
            image: [], 
            // userId: "",
            documents: [], 
            baseUrl: "https://wossdb.wossautoid.com/", 
            userId: this.$route.params.id,
            vehicleId: "",
            features:"",
            description:"",
            office_phone:"",
            trade_name:"",
            rfc:"",
            userImage: '',
            ownerImage:'',
            first_name:'',
            last_name:'',
            email:'',
            phone_number:'',
            city:'',
            state:'',
            country:'',
            postcode:'',
            street_address:'',
            type:'',

        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, 
        BSpinner,
    },


    created() {
        this.fetchVehicleData();
    },
    methods: {
        fetchVehicleData() {
    axios
        .get(`vehicleShow/${this.userId}`)
        .then((response) => {
            const user = response.data.data;

            // Assign vehicle data
            this.company_name = user.company_name; 
            this.model = user.model?.title || '';
            this.color = user.color;
            this.license_plate = user.license_plate;
            this.brand = user.brand?.name || '';
            this.types = user.types;
            this.engine_number = user.engine_number;
            this.image = user.image || '';
            this.registration_number = user.registration_number;
            this.vin = user.vin;
            this.uic = user.uic;
            this.year = user.year;
            this.status = user.status;
            this.features = user.features;
            this.description = user.description;
            this.documents = user.documents || [];

            // Assign personal user data
            if (user.user) {
                this.first_name = user.user.first_name;
                this.last_name = user.user.last_name;
                this.email = user.user.email;
                this.phone_number = user.user.phone_number;
                this.city = user.user.city || '';
                this.state = user.user.state;
                this.country = user.user.country || '';
                this.postcode = user.user.postcode;
                this.street_address = user.user.street_address;
                this.type = user.user.type;
                this.userCompanyName = user.user.company_name || '';
                this.rfc = user.user.rfc || '';
                this.trade_name = user.user.trade_name || '';
                this.office_phone = user.user.office_phone || '';
                this.userImage = user.user.image || '';
                this.document = user.user.documents || '';

            }

            // Assign ownership data
            if (user.old_onwer) {
                
                this.oldOwnerFirstName  = user.old_onwer.first_name;
                this.oldOwnerLastName = user.old_onwer.last_name;
                this.oldOwnerEmail = user.old_onwer.email;
                this.oldOwnerPhoneNumber = user.old_onwer.phone_number;
                this.oldOwnerCity = user.old_onwer.city || '';
                this.oldOwnerState = user.old_onwer.state;
                this.oldOwnerCountry = user.old_onwer.country || '';
                this.oldOwnerPostCode = user.old_onwer.postcode;
                this.oldOwnerAddress = user.old_onwer.street_address;
                this.oldOwnerUserType = user.old_onwer.type;
                this.oldOwnerCompanyName = user.old_onwer.company_name || ''; 
                this.oldOwnerRFC = user.old_onwer.rfc || '';
                this.oldOwnerTradeName = user.old_onwer.trade_name || '';
                this.oldOwnerOfficePhone = user.old_onwer.office_phone || '';
                this.ownerImage = user.old_onwer.image || '';
              
                this.documentss = user.old_onwer.documents || '';
            }
        })
        .catch((error) => {
            console.error("Error fetching user data:", error);
        });
        },
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
           
        },
     
        onProfilePictureChange(event) {
            const files = event.target.files;
            if (files && files.length > 0) {
                this.image = Array.from(files).map(file => URL.createObjectURL(file));
            }
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
        openDocuments(url) {
            window.open(url, "_blank");
        },
        
       
    downloadDocument(docPath) {
    const fileUrl = `${this.baseUrl}${docPath}`;
    const fileName = docPath.split('/').pop();

    // Fetch the file as a blob
    fetch(fileUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/octet-stream",
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Unable to download file. Status: ${response.status}`);
            }
            return response.blob();
        })
        .then((blob) => {
            // Create a download link and trigger it
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the object URL
        })
        .catch((error) => {
            console.error("Error downloading the document:", error);
        });
}
,
    },
};
</script>
