<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
          <div class="col-12 mt-16">
            <b-form @submit.prevent="addUser" v-if="show">
              <div
                style="
                  background-color: rgb(97, 116, 152);
                  height: 32px;
                  border-radius: 4px;
                "
              >
                <h4
                  style="
                    color: rgb(223, 227, 238);
                    margin-left: 5px;
                    font-weight: bold;
                  "
                >
                Product Information
                </h4>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Title:" label-for="name">
                    <b-form-input id="name" placeholder="Enter name"
                        v-model="name" disabled>
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Amount:" label-for="price">
                    <b-form-input id="price" placeholder="Enter price"
                        v-model="price" disabled>
                    </b-form-input>
                </b-form-group>
            </div>
                <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Description:" label-for="description">
                    <b-form-input id="description" placeholder="Enter description"
                        v-model="description" disabled>
                    </b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Quantity:" label-for="quantity">
                  <b-form-input id="quantity" placeholder="Enter quantity"
                      v-model="quantity" disabled>
                  </b-form-input>
              </b-form-group>
             </div>
  
              <div class="col-md-12 col-12">
                <b-form-group id="input-group-2" label="Brand Pictures:" label-for="image">
                  <!-- Check if images are available -->
                  <div v-if="image && image.length > 0" class="d-flex flex-wrap">
                    <div
                      v-for="(img, index) in image"
                      :key="index"
                      style="margin-right: 10px; margin-bottom: 10px"
                    >
                      <img
                        :src="'https://wossdb.wossautoid.com/' + img"
                        alt="Brand Picture"
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <span>No image available</span>
                  </div>
                </b-form-group>
              </div>

              </div>
            </b-form>
          </div>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
    BToast,
  } from "bootstrap-vue";
  import axios from "../../../axios";
  export default {
    data() {
      return {
        show: true,
        description: "", 
        name: "",
        price:"",
        quantity:'',
        // image: '',
        image: [],
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BForm,
      BFormGroup,
      BFormCheckboxGroup,
      BFormCheckbox,
      BFormSelect,
      BFormInput,
      BToast,
      BSpinner,
    },
    created() {
        axios
        const userId = this.$route.params.id;
        axios
            .get(`ProductShow/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.name = this.editedUser.name;
                this.price = this.editedUser.price;
                this.image = this.editedUser.image ||'';
                this.description = this.editedUser.description ||'';
                this.quantity = this.editedUser.quantity ||'';            
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
           
        },
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
  };
  </script>
  