<template>
  <div class="notification-detail-page">
    <b-card class="mb-3" header="Notification Details">
      <h4 class="card-title blue-title">Title: {{ notification.title }}</h4>
      <p class="card-text"><strong>Message:</strong> {{ notification.message }}</p>
    
      <p class="card-text">
        <strong>Vehicle Identification Number:</strong>
        <router-link :to="{ name: 'ListVehicle', params: { vin: notification.vehicle?.vin } }">
          {{ notification.vehicle?.vin }}
        </router-link>
      </p>

      <p class="card-text">
        <strong>Registration Number:</strong> {{ notification.vehicle?.registration_number }}
      </p>
      <p class="card-text">
        <strong>Date and Time:</strong> {{ formattedDate }}
      </p>
             <!-- Image  code here  -->
                    
                    <div style="margin-left: 3px; margin-bottom: 15px">
                      <img  v-if="image"
                        :src="'https://wossdb.wossautoid.com/' + image"
                        alt="Stolen  Place"
                        width="100"
                        height="100"
                      />
                      <div v-else>
                        <span>
                          No image available
                        </span>
                      </div>
                    </div>
                 
      <!-- Trigger the modal with the Delete button -->
      <b-button variant="primary" @click="showDeleteModal" class="mt-3">Delete</b-button>
    </b-card>

    <!-- Delete Confirmation Modal -->
    <b-modal
      id="delete-confirmation-modal"
      ref="deleteModal"
      title="Confirm Delete"
      @ok="deleteNotification"
      ok-title="Confirm"
      ok-variant="danger"
      cancel-title="Cancel"
      hide-footer
    >
      <p>Are you sure you want to delete this notification?</p>
      <b-button variant="primary" @click="confirmDelete" class="mr-2">Confirm</b-button>
      <b-button variant="primary" @click="cancelDelete">Cancel</b-button>
    </b-modal>
  </div>
</template>


<script>
import axios from "../../../axios";
import { BCard, BButton, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BModal,
  },
  data() {
    return {
      notification: {},
      // notification: null,
      formattedDate: '', 
      image: "",
    };
  },
  
  methods: {
    async fetchNotificationDetail() {
      const id = this.$route.params.id; 
      try {
        const response = await axios.get(`showNotification/${id}`);
        this.notification = response.data.notification;
        this.image = this.notification?.image || "";
        
        this.formattedDate = this.formatDate(this.notification.created_at);
      } catch (error) {
   
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },


    showDeleteModal() {
      this.$refs.deleteModal.show();
    },

    // Method to delete the notification
    async deleteNotification() {
      const id = this.$route.params.id; 
      try {
        await axios.delete(`deleteNotificationAdmin/${id}`); 
        this.$refs.deleteModal.hide(); 
        this.$router.push("/dashboards"); 
      } catch (error) {
      
      }
    },
    confirmDelete() {
      this.deleteNotification(); 
    },

    cancelDelete() {
      this.$refs.deleteModal.hide();
    },
  },
  mounted() {
    this.fetchNotificationDetail();
  },
  watch: {
    '$route.params.id'(newId) {
      this.fetchNotificationDetail(); 
    }
  },
};
</script>


<style scoped>
.blue-title {
  color: blue;
}
</style>
