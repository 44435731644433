<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Theft Information
                            </h4>
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                    <b-form-input id="company_name" placeholder="Enter company name"
                                        v-model="company_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->
                            <div class="col-md-4 col-12">
                                <b-form-group label="Brand Name:" label-for="make">
                                    <b-form-input id="brand" placeholder="Enter vehicle brand" v-model="brand.name" disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group label="Brand Model:" label-for="model">
                                    <b-form-input id="model" placeholder="Enter vehicle model" v-model="model.title" disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter vehicle color"
                                        v-model="color" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="License Plate: "
                                    label-for="license_plate">
                                    <b-form-input id="license_plate"
                                        placeholder="Enter license plate" v-model="license_plate"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number: "
                                    label-for="engine_number">
                                    <b-form-input id="engine_number"
                                        placeholder="Enter engine number" v-model="engine_number" disabled
                                        ></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Approve Status: "
                                    label-for="types">
                                    <b-form-input id="types" 
                                        placeholder="Enter types" v-model="types"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration  Number: "
                                    label-for="registration_number">
                                    <b-form-input id="registration_number"
                                        placeholder="Enter registration number" v-model="registration_number"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Year: "
                                    label-for="year">
                                    <b-form-input id="year" 
                                        placeholder="Enter vehicle year" v-model="year"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Identification Number:"
                                    label-for="vin">
                                    <b-form-input id="vin" 
                                        placeholder="Enter vehicle identification number:" v-model="vin"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Unique Identification Code:" label-for="uic">
                                <b-form-input id="uic" placeholder="Enter unique identification code" v-model="uic" disabled></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Status: "
                                    label-for="status">
                                    <b-form-input id="status" 
                                        placeholder="Enter vehicle status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                          
                    <div class="col-md-4 col-12">
                    <b-form-group id="input-group-2" label="Vehicle Images:" label-for="images">
                        <div v-if="image && image.length" style="margin-left: 3px; margin-bottom: 15px">
                        <img
                            v-for="(image, index) in image"
                            :key="index"
                            :src="'https://wossdb.wossautoid.com/' + image"
                            alt="Vehicle Image"
                            width="100"
                            height="100"
                            style="margin-right: 10px"
                        />
                        </div>
                        <div v-else>
                            <span>
                                No  image available
                            </span>
                        </div>
                    </b-form-group>
                    </div>
                    <!-- Document code  here    -->
              <div class="col-md-4 col-12">
                    <b-form-group id="input-group-2" label="Vehicle Documents:" label-for="documents">
                        <div v-if="documents && documents.length" style="margin-left: 3px; margin-bottom: 15px">
                            <div v-for="(doc, index) in documents" :key="index" style="margin-bottom: 10px;">
                                <span 
                                    style="margin-right: 10px; margin-bottom: 10px; margin-top: 10px; cursor: pointer;" 
                                    @click="openDocument(doc.documents)"
                                >
                                    <i class="fas fa-file-pdf" style="margin-right: 5px;"></i>
                                    Pdf 
                                </span>
                                <button @click="downloadDocument(doc.documents)" class="btn btn-primary btn-sm ml-2">
                                    Download
                                </button>
                            </div>
                        </div>
                       <div v-else>
                        <span>No document available</span>
                       </div> 
                    </b-form-group>
                </div>

                        </div>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            commission: "",
            selectedCarType: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            company_name: "",
            model: "",
            color: "",
            license_plate: "",
            brand: "",
            types: "",
            registration_number: "",
            vin: "",
            uic: "",
            year: "",
            status: "",
            image: [], 
            documents: [],
            engine_number:"",
            
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {
        // Load the clients data when the component is created
        const userId = this.$route.params.id;
        axios
            .get(`vehicleShow/${userId}`)
            .then((response) => {
                const user = response.data.data; // Assuming the response is an array with a single user object
                this.editedUser = user; // Save the entire user object for later use
                this.company_name = user.company_name ||'';
                this.model = user.model;
                this.color = user.color;
                this.license_plate = user.license_plate;
                this.brand = user.brand;
                this.types = user.types;
                this.engine_number = user.engine_number;
                this.image = user.image;
                this.registration_number = user.registration_number;
                this.vin = user.vin ||'';
                this.uic = user.uic ||'';
                this.year = user.year;
                this.status = user.status;
                this.documents = user.documents ||'';
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
        onProfilePictureChange(event) {
            const files = event.target.files;
            if (files && files.length > 0) {
                this.image = Array.from(files).map(file => URL.createObjectURL(file));
            }
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
   
    downloadDocument(docPath) {
        const link = document.createElement('a');
        link.href = `https://wossdb.wossautoid.com/${docPath}`;
        link.setAttribute('download', docPath.split('/').pop());
        link.setAttribute('target', '_blank'); 
        link.click();
    },


    },
};
</script>
