import axios from "axios";

// Create an axios instance
const axiosInstance = axios.create({
    baseURL: "https://wossdb.wossautoid.com/api/"
});

// Interceptor to attach token to every request
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        console.log('Request Headers:', config.headers); 

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
