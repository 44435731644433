<template>
    <div id="map"></div>
</template>

<script>
import axios from "../../../axios";

export default {
    name: 'LiveVehicleMap',
    data() {
        return {
            map: null,
            marker: null,
            polyline: null,
            path: [],
            updateInterval: null // Store the interval ID
        };
    },
    mounted() {
        // Ensure the Google Maps API is loaded
        if (window.google && window.google.maps) {
            this.fetchVehicleLocation();
            this.updateInterval = setInterval(this.updateVehiclePosition, 5000);
        } else {
            console.error('Google Maps not loaded');
        }
    },
    beforeDestroy() {
        // Clear the interval when the component is destroyed
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    },
    methods: {
        async fetchVehicleLocation() {
            const { userId, vehicleId } = this.$route.params;
            try {
                const response = await axios.get(`/vehicle-location/${userId}/${vehicleId}`);
                if (response.data.success) {
                    const vehicle = response.data.data;
                    this.initMap(vehicle);
                } else {
                    console.error('Vehicle not found');
                }
            } catch (error) {
                console.error('Error fetching vehicle location:', error);
            }
        },
        initMap(vehicle) {
            const initialPosition = { lat: vehicle.latitude, lng: vehicle.longitude };
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 14,
                center: initialPosition
            });

            this.marker = new google.maps.Marker({
                position: initialPosition,
                map: this.map,
                title: 'Vehicle Location',
                icon: {
                    url: '/favicons/car.png',
                    scaledSize: new google.maps.Size(50, 50)
                }
            });

            this.path.push(initialPosition);

            this.polyline = new google.maps.Polyline({
                path: this.path,
                geodesic: true,
                strokeColor: '#4285F4',
                strokeOpacity: 1.0,
                strokeWeight: 3,
                map: this.map
            });
        },
        async updateVehiclePosition() {
            const { userId, vehicleId } = this.$route.params;
            try {
                const response = await axios.get(`/vehicle-location/${userId}/${vehicleId}`);
                if (response.data.success) {
                    const vehicle = response.data.data;
                    const newPosition = { lat: vehicle.latitude, lng: vehicle.longitude };
                    this.marker.setPosition(newPosition);
                    this.map.setCenter(newPosition);
                    this.path.push(newPosition);
                    this.polyline.setPath(this.path);
                } else {
                    console.error('Vehicle not found');
                }
            } catch (error) {
                console.error('Vehicle not found:', error);
            }
        }
    }
};
</script>

<style scoped>
#map {
    height: 90vh;
    width: 100%;
}
</style>
