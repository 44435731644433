import Vue from 'vue'
import VueCompositionAPI, { createApp, h } from '@vue/composition-api'
import VueI18n from 'vue-i18n';
import store from './store'
import router from './router'
import App from './App.vue'
// PrimeVue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme
import 'primevue/resources/primevue.min.css'; // Core CSS
import 'primeicons/primeicons.css';

// Vue Page Transition
import VuePageTransition from 'vue-page-transition'

// Bootstrap
import { BootstrapVueIcons, PopoverPlugin, ToastPlugin, ModalPlugin, SidebarPlugin, TooltipPlugin, TabsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Highlightjs
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/mono-blue.css';

// Apexcharts
import VueApexCharts from 'vue-apexcharts'
// ............. 
import en from './locales/en.json';
import sp from './locales/sp.json';
Vue.use(VueI18n);
const messages = {
  en, 
  sp, 
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

// .................. 
// Axios
import axios from 'axios'
import Pusher from 'pusher-js';
Vue.prototype.$pusher = new Pusher('b89af738d362d3ca27d2', {
  cluster: 'ap2',
});
// Configure Axios
axios.defaults.baseURL = "https://wossdb.wossautoid.com/api/";
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

// Bootstrap Plugin
Vue.use(TabsPlugin)
Vue.use(TooltipPlugin)
Vue.use(SidebarPlugin)
Vue.use(PopoverPlugin)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Vue Page Transition
Vue.use(VuePageTransition)

// Highlight
Vue.use(VueHighlightJS)
// PrimeVue
Vue.use(PrimeVue);
// Apexchart
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Style
require('@/assets/icons/iconly/index.min.css')
require('@/assets/icons/remix-icon/index.min.css')
require('@/assets/scss/app.scss')

// Composition Api
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
