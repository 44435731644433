<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
          <div class="col-12 mt-16">
            <b-form @submit.prevent="addUser" v-if="show">
              <div
                style="
                  background-color: rgb(97, 116, 152);
                  height: 32px;
                  border-radius: 4px;
                "
              >
                <h4
                  style="
                    color: rgb(223, 227, 238);
                    margin-left: 5px;
                    font-weight: bold;
                  "
                >
                  Brand Information
                </h4>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-1" label="Brand Name:" label-for="name">
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Enter name"
                      autocomplete="off"
                      v-model="name"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Category Name:" label-for="category_name">
                    <b-form-input
                      id="category_name"
                      v-model="categoryTitle"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group id="input-group-2" label="Brand Picture:" label-for="image">
                    <div style="margin-left: 3px; margin-bottom: 15px">
                      <div v-if="image">
                      <img
                        :src="'https://wossdb.wossautoid.com/' + image"
                        alt="Brand Picture"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div v-else>
                    <span>No image available</span>
                  </div>  
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
    BToast,
  } from "bootstrap-vue";
  // import axios from "axios";
  import axios from "../../../axios";
  
  export default {
    data() {
      return {
        show: true,
        name: "",
        image: "",
        category_id: "", 
        categoryTitle: "", 
        categories: [],
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BForm,
      BFormGroup,
      BFormCheckboxGroup,
      BFormCheckbox,
      BFormSelect,
      BFormInput,
      BToast,
      BSpinner,
    },
    created() {
      const userId = this.$route.params.id;
      axios
        .get(`brandShow/${userId}`)
        .then((response) => {
          this.editedUser = response.data.data;
          this.name = this.editedUser.name;
          this.category_id = this.editedUser.category_id;
          this.image = this.editedUser.image ||'';
  
          // Find the category title based on category_id
          const category = this.categories.find(
            (category) => category.value === this.category_id
          );
          this.categoryTitle = category ? category.text : "Unknown Category";
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    },
    mounted() {
      axios
        .get("category")
        .then((response) => {
          if (response.data.success && response.data.data) {
            this.categories = response.data.data.map((category) => ({
              value: category.id,
              text: category.title,
            }));

            // Update category title once categories are loaded
            const category = this.categories.find(
              (category) => category.value === this.category_id
            );
            this.categoryTitle = category ? category.text : "Unknown Category";
          } else {
            console.error("Unexpected response format:", response.data);
          }
        })
        .catch((error) => {

        });
    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
       
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
  };
  </script>
  