<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Brand Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Brand Name:" label-for="name">
                                    <b-form-input id="name" type="text" placeholder="Enter  name" autocomplete="off"
                                        v-model="name">
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Category Name:" label-for="category_id">
                                <b-form-select id="category_id" v-model="category_id" :options="categories" required>
                                </b-form-select>
                            </b-form-group>
                            </div>
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <div v-if="editedUser.image">
                                        <img :src="'https://wossdb.wossautoid.com/' + image
                                            " alt="Picture" style="max-width: 100px; max-height: 100px" />
                                        <input type="file" accept="image/*" id="image"
                                            @change="onProfilePictureChange" />
                                         </div>
                                    <div v-else>
                                        <span>No  image available</span>
                                    </div>   
                                    </div>
                                </b-form-group>
                            </div>
                        </div>

                        <!-- Bank Information End -->
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            name: "",
            image: "",
            category_id: "", 
            categories: [], 


        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {
        // Load the clients data when the component is created
        axios
        const userId = this.$route.params.id;
        axios
            .get(`brandShow/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.name = this.editedUser.name;
                this.category_id = this.editedUser.category_id;
                this.image = this.editedUser.image ||'';
            
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    mounted() {
                axios.get('category')
                    .then(response => {
                        // Check if the response is successful and contains data
                        if (response.data.success && response.data.data) {
                            // Map the API response to the format required by b-form-select
                            this.categories = response.data.data.map(category => ({
                                value: category.id,  
                                text: category.title 
                            }));
                           
                        } else {
                            console.error('Unexpected response format:', response.data);
                        }
                    })
                    .catch(error => {
        
                    });
            },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file

            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("image", this.image);
            formData.append("category_id", this.category_id);
         
            axios
                .post(`brandUpdate/${this.editedUser.id}`, formData)
                .then((response) => {

                    this.$bvToast.toast("Brand Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", 
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListBrand' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;

                    this.isLoading = false;
                });
        },

        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },


        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>