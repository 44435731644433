<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                               <!-- {{ $t('personal_info') }} -->
                                 Personal Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name"  autocomplete="off"
                                        v-model="first_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                                <b-form-input id="last_name"  autocomplete="off"
                                    v-model="last_name" disabled>
                                </b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Email:" label-for="email">
                                    <b-form-input id="email"  autocomplete="off"
                                        v-model="email" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Phone Number:" label-for="phone_number">
                                    <b-form-input id="phone_number" 
                                        v-model="phone_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="street_address">
                                    <b-form-input id="street_address" 
                                        v-model="street_address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="City:" label-for="city">
                                    <b-form-input id="city" 
                                    v-model="city" disabled></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="State:" label-for="state">
                                    <b-form-input id="state" v-model="state" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" v-model="country" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Post Code:" label-for="postcode">
                                    <b-form-input id="postcode" v-model="postcode" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                            <b-form-group id="input-group-2" label="Company Name:" label-for="company_name">
                                <b-form-input id="company_name" placeholder="Enter company name" :value="userCompanyName || ''" disabled></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registro Federal Contribuyente:" label-for="rfc">
                                    <b-form-input id="rfc" placeholder="Enter rfc"
                                       :value="rfc || ''" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Trade Name:" label-for="trade_name">
                                    <b-form-input id="trade_name" placeholder="Enter trade name"
                                       :value="trade_name || ''" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Office Number: "
                                    label-for="office_phone">
                                    <b-form-input id="office_phone" 
                                        placeholder="Enter office phone" :value="office_phone || ''"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- .....................................  -->

                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                 Shipping Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Product Name:" label-for="product_name">
                                    <b-form-input id="product_name"  autocomplete="off"
                                        v-model="product_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Email:" label-for="email">
                                    <b-form-input id="email"  autocomplete="off"
                                        v-model="email" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Phone Number:" label-for="contact_number">
                                    <b-form-input id="contact_number" autocomplete="off"
                                        v-model="contact_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Order Number:" label-for="order_number">
                                    <b-form-input id="order_number" autocomplete="off"
                                        v-model="order_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Payment Status:" label-for="payment_status">
                                    <b-form-input id="payment_status"  autocomplete="off"
                                        v-model="payment_status" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Payment Method:" label-for="payment_method">
                                    <b-form-input id="payment_method"
                                        v-model="payment_method" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="address">
                                    <b-form-input id="address" type="text"
                                        v-model="address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Zip Code:" label-for="zip_code">
                                    <b-form-input id="zip_code" 
                                        v-model="zip_code" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Quantity:" label-for="quantity">
                                    <b-form-input id="quantity" type="quantity" placeholder="Enter quantity"
                                        v-model="quantity" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Qunatity Amount:" label-for="amount">
                                    <b-form-input id="amount" placeholder="Enter quantity amount "
                                        v-model="amount" disabled></b-form-input>
                                </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            product_name: "",
            user_name: "",
            address: "",
            quantity: "",
            amount: "",
            status: '',
            first_name:"",
            last_name:"",
            successMessage: "",
            phone_number:"",
            street_address:"",
            city:"",
            state:"",
            postcode:"",
            country:"",
            contact_number:"",
            order_number:"",
            payment_status:"",
            payment_method:"",
            zip_code:"",
            email:"",
            company_name: "",
            office_phone:"",
            trade_name:"",
            rfc:"",
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BToast,
        BSpinner,
    },

    created() {
        // Load the order data when the component is created
        const userId = this.$route.params.id;
        axios
            .get(`OrderShow/${userId}`)
            .then((response) => {
                const data = response.data.data;
                this.first_name = data.user.first_name; 
                this.last_name = data.user.last_name; 
                this.email = data.user.email; 
                this.phone_number = data.user.phone_number;
                this.street_address = data.user.street_address; 
                this.city = data.user.city; 
                this.state = data.user.state; 
                this.postcode = data.user.postcode; 
                this.country = data.user.country; 
                this.company_name = data.user.company_name ||'';
                this.rfc = data.user.rfc||'';
                this.trade_name = data.user.trade_name||'';
                this.office_phone = data.user.office_phone||'';
                this.product_name = data.product.name; 
                this.address = data.address;
                this.status = data.status;
                this.quantity = data.quantity;
                this.amount = data.amount;
                this.zip_code = data.zip_code;
                this.payment_method = data.payment_method;
                this.payment_status = data.payment_status;
                this.order_number = data.order_number;
                this.contact_number = data.contact_number;
                this.email = data.email;
            })
            .catch((error) => {
                console.error("Error fetching order data:", error);
            });
    },
    methods: {
        codeClick() {
            this.codeActive = !this.codeActive;
        },
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.image = file;
            }
        },
    
    },
};
</script>

