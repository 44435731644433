<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
            <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                      <div v-if="type === 'user'">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Personal Information
                            </h4>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" type="text" placeholder="Enter first name" autocomplete="off"
                                        v-model="first_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Middle Name:" label-for="second_name">
                                    <b-form-input id="second_name" type="text" placeholder="Enter  second name" autocomplete="off"
                                        v-model="second_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" type="text" placeholder="Enter  last name" autocomplete="off"
                                        v-model="last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Second Surname:" label-for="second_last_name">
                                    <b-form-input id="second_last_name" type="text" placeholder="Enter second surname" autocomplete="off"
                                        v-model="second_last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Date of Birth:" label-for="birthdate">
                                    <b-form-input id="birthdate" type="date" placeholder="Enter date of  birth" autocomplete="off"
                                        v-model="birthdate" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Profession:" label-for="profession">
                                    <b-form-input id="profession" type="text" placeholder="Enter profession" autocomplete="off"
                                        v-model="profession" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Voter Credendial Number:" label-for="voter_credential_number">
                                    <b-form-input id="voter_credential_number" type="text" placeholder="Enter voter credential number" autocomplete="off"
                                        v-model="voter_credential_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Zip Code:" label-for="postcode">
                                    <b-form-input id="postcode" type="text" placeholder="Enter Zip code"
                                        v-model="postcode" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="street_address">
                                    <b-form-input id="street_address" type="text" placeholder="Enter street address"
                                        v-model="street_address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                          
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Email Address:" label-for="email">
                                    <b-form-input id="email" type="email" placeholder="Enter email address"
                                        v-model="email" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>



                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Mobile:" label-for="phone_number">
                                    <b-form-input id="phone_number" type="number" placeholder="Enter mobile number"
                                    v-model="formattedPhoneNumber"  disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city" v-model="city" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country" v-model="country"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Profile Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                     <div v-if="image"> 
                                        <img :src="'https://wossdb.wossautoid.com/' + image
                                            " alt="Profile Picture" width="100" height="100" />
                                     </div>
                                     <div v-else>
                                      <span>No image  available</span>
                                     </div>    
                                    </div>
                                </b-form-group>
                            </div>
                            <!-- Document  code  here  -->
                         
                            <div class="col-md-4 col-12">
                      <b-form-group id="input-group-2" label="Document:" label-for="documents">
                        <div style="margin-left: 3px; margin-bottom: 15px">
                          <!-- Document Preview -->
                          <div v-if="editedUser.documents">
                            <a :href="'https://wossdb.wossautoid.com/' + editedUser.documents" target="_blank">
                              <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                            </a>
                          </div>
                          <div v-else>
                            <span>No document available</span>
                          </div>
                        </div>
                      </b-form-group>
                    </div>

                        </div>
                      </div>
                      <div v-else-if="type === 'company'">
                        
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Personal Information
                            </h4>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="First Name:" label-for="first_name">
                                    <b-form-input id="first_name" type="text" placeholder="Enter first name" autocomplete="off"
                                        v-model="first_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Middle Name:" label-for="second_name">
                                    <b-form-input id="second_name" type="text" placeholder="Enter  second name" autocomplete="off"
                                        v-model="second_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Last Name:" label-for="last_name">
                                    <b-form-input id="last_name" type="text" placeholder="Enter  last name" autocomplete="off"
                                        v-model="last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Second Surname:" label-for="second_last_name">
                                    <b-form-input id="second_last_name" type="text" placeholder="Enter second surname" autocomplete="off"
                                        v-model="second_last_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Date of Birth:" label-for="birthdate">
                                    <b-form-input id="birthdate" type="date" placeholder="Enter date of  birth" autocomplete="off"
                                        v-model="birthdate" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Profession:" label-for="profession">
                                    <b-form-input id="profession" type="text" placeholder="Enter profession" autocomplete="off"
                                        v-model="profession" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Voter Credendial Number:" label-for="voter_credential_number">
                                    <b-form-input id="voter_credential_number" type="text" placeholder="Enter voter credential number" autocomplete="off"
                                        v-model="voter_credential_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Zip Code:" label-for="postcode">
                                    <b-form-input id="postcode" type="text" placeholder="Enter Zip code"
                                        v-model="postcode" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Address:" label-for="street_address">
                                    <b-form-input id="street_address" type="text" placeholder="Enter street address"
                                        v-model="street_address" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
              
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Email Address:" label-for="email">
                                    <b-form-input id="email" type="email" placeholder="Enter email address"
                                        v-model="email" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>



                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Mobile:" label-for="phone_number">
                                    <b-form-input id="phone_number" type="number" placeholder="Enter mobile number"
                                        v-model="phone_number" disabled></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city" v-model="city" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country" v-model="country"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Status:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status" v-model="status"
                                        disabled></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Profile Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                      <div v-if="image">
                                        <img :src="'https://wossdb.wossautoid.com/' + image
                                            " alt="Profile Picture" width="100" height="100" />
                                      </div>
                                    <div>
                                      <span>No image available</span>
                                    </div>    
                                    </div>
                                </b-form-group>
                            </div>
                            <!-- Document  code  here  -->
                         
                            <div class="col-md-4 col-12">
                      <b-form-group id="input-group-2" label="Document:" label-for="documents">
                        <div style="margin-left: 3px; margin-bottom: 15px">
                          <!-- Document Preview -->
                          <div v-if="editedUser.documents">
                            <a :href="'https://wossdb.wossautoid.com/' + editedUser.documents" target="_blank">
                              <i class="fas fa-file-pdf" style="margin-right: 5px;"></i> PDF
                            </a>
                          </div>
                          <div v-else>
                            <span>No document available</span>
                          </div>
                        </div>
                      </b-form-group>
                    </div>

                        </div>
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Company Information
                            </h4>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Company Name:" label-for="company_name">
                                    <b-form-input id="company_name" type="text" placeholder="Enter company name" autocomplete="off"
                                        v-model="company_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                      
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Registro Federal Contribuyente:" label-for="rfc">
                                    <b-form-input id="rfc" type="text" placeholder="Enter  rfc" autocomplete="off"
                                        v-model="rfc" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Trade Name:" label-for="trade_name">
                                    <b-form-input id="trade_name" type="text" placeholder="Enter trade name" autocomplete="off"
                                        v-model="trade_name" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Office Phone Number:" label-for="office_phone">
                                    <b-form-input id="office_phone" type="text" placeholder="Enter office phone nnumber" autocomplete="off"
                                        v-model="office_phone" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                      </div>
                    </b-form>
                </div>
            <!-- ............  -->
          <div class="col-12 mt-16" v-for="(vehicle, index) in vehicles" :key="vehicle.id">
            <b-form @submit.prevent="addUser" v-if="show">
              <div style="background-color: rgb(97, 116, 152); height: 32px; border-radius: 4px;">
                <h4 style="color: rgb(223, 227, 238); margin-left: 5px; font-weight: bold;">
                  Vehicle Information ({{ index + 1 }})
                </h4>
              </div>
              <div class="row">
                <div class="col-md-4 col-12">
                  <b-form-group label="Company Name:" label-for="company_name">
                    <b-form-input
                      id="company_name"
                      placeholder="Enter company name"
                      v-model="vehicle.company_name"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <!-- <div class="col-md-4 col-12">
                <b-form-group label="Brand Model:" label-for="model">
                    <b-form-input id="model" placeholder="Enter vehicle model" v-model="vehicle.model.title" disabled></b-form-input>
                </b-form-group>
                </div> -->
                <div class="col-md-4 col-12">
              <b-form-group label="Brand Model:" label-for="model">
                <b-form-input
                  id="model"
                  placeholder="Enter vehicle model"
                  :value="vehicle.model?.title || ''" 
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>

                <div class="col-md-4 col-12">
                  <b-form-group label="Vehicle Color:" label-for="color">
                    <b-form-input
                      id="color"
                      placeholder="Enter vehicle color"
                      v-model="vehicle.color"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="License Plate:" label-for="license_plate">
                    <b-form-input
                      id="license_plate"
                      placeholder="Enter license plate"
                      v-model="vehicle.license_plate"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                <b-form-group label="Brand Name:" label-for="make">
                    <b-form-input id="brand" placeholder="Enter vehicle brand"
                    :value="vehicle.brand?.name || ''" 
                    disabled></b-form-input>
                </b-form-group>
                 </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Approve Status:" label-for="types">
                    <b-form-input
                      id="types"
                      placeholder="Enter types"
                      v-model="vehicle.types"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Registration Number:" label-for="registration_number">
                    <b-form-input
                      id="registration_number"
                      placeholder="Enter registration number"
                      v-model="vehicle.registration_number"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Vehicle Year:" label-for="year">
                    <b-form-input
                      id="year"
                      placeholder="Enter vehicle year"
                      v-model="vehicle.year"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Vehicle Identification Number:" label-for="vin">
                    <b-form-input
                      id="vin"
                      placeholder="Enter vehicle identification number"
                      v-model="vehicle.vin"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Unique Identification Code:" label-for="uic">
                    <b-form-input
                      id="uic"
                      placeholder="Enter unique identification code"
                      v-model="vehicle.uic"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 col-12">
                  <b-form-group label="Vehicle Status:" label-for="status">
                    <b-form-input
                      id="status"
                      placeholder="Enter vehicle status"
                      v-model="vehicle.status"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
                
            <div class="col-md-4 col-12">
                <b-form-group label="Vehicle Images:" label-for="image">
                  <div v-if="vehicle.image && vehicle.image.length" style="margin-left: 3px; margin-bottom: 15px">
                    <img
                      v-for="(img, imgIndex) in vehicle.image"
                      :key="imgIndex"
                      :src="'https://wossdb.wossautoid.com/' + img"
                      alt="Vehicle Image"
                      width="100"
                      height="100"
                      style="margin-right: 10px"
                    />
                  </div>
                  <div v-else>
                    <span>
                      No image available
                    </span>
                  </div>
                </b-form-group>
              </div>
              <!-- Document  code   here  -->

              <div class="col-md-4 col-12">
              <b-form-group id="input-group-2" label="Documents:" label-for="documents">
              <div v-if="vehicle.documents && vehicle.documents.length" style="margin-left: 3px; margin-bottom: 15px;">
                <div v-for="(doc, docIndex) in vehicle.documents" :key="docIndex" style="display: flex; align-items: center;">
                  <!-- Check if the document is an image -->
                  <template v-if="isImage(doc.documents)">
                    <img
                      :src="'https://wossdb.wossautoid.com/' + doc.documents"
                      alt="Document"
                      width="100"
                      height="100"
                      style="margin-right: 10px; margin-bottom: 10px;
                      margin-top: 10px;
                      "
                    />
                  </template>
                  
                  <template v-else>
                  <span style="margin-right: 10px; margin-bottom: 10px; margin-top: 10px;">
                    <i class="fas fa-file-pdf" style="margin-right: 5px;"></i>Pdf
                  </span>
                </template>
                </div>
              </div>
            </b-form-group>
          </div>
              
              </div>
            </b-form>
          </div>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
  } from "bootstrap-vue";
  // import axios from "axios";
  import axios from "../../../axios";
  import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";
  
  export default {
    data() {
      return {
        show: true,
        vehicles: [], // Array to hold multiple vehicles
          modalVisibleDoument: false,
            vehicleId: "",
            fileToUpload: null,
            documents: null,
            isLoading: false,
            successMessage: "",
            // Add Driver
            first_name: "",
            second_name:"",
            last_name:"",
            second_last_name:"",
            birthdate:"",
            profession:"",
            voter_credential_number:"",
            postcode:"",
            type:"user",
            street_address:"",
            documents:"",

            email: "",
            phone_number: "",
            city:"",
            country:"",
            image: "",
            company_name: "",
            model: "",
            color: "",
            license_plate:"",
            brand:"",
            types:"",
            registration_number:"",
            vin:"",
            uic:"",
            year:"",
            status:"",
            userId: "",
            image: [],

            company_name: "",
            rfc:"",
            trade_name:"",
            office_phone:"",
                    
        
      };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },
    created() {
        
        const userId = this.$route.params.id;
        axios
            .get(`UserGet/${userId}`)
            .then((response) => {
                this.editedUser = response.data.data;
                // Set the data properties with values from editedUser
                this.first_name = this.editedUser.first_name;
                this.second_name = this.editedUser.second_name;
                this.last_name = this.editedUser.last_name;
                this.second_last_name = this.editedUser.second_last_name;
                this.birthdate = this.editedUser.birthdate;
                this.profession = this.editedUser.profession;
                this.voter_credential_number = this.editedUser.voter_credential_number;
                this.postcode = this.editedUser.postcode;
                this.type = this.editedUser.type;
                this.street_address = this.editedUser.street_address;
                this.phone_number = this.editedUser.phone_number.replace(/^\+/,'');
                this.city = this.editedUser.city ||'';
                this.email = this.editedUser.email;
                this.country = this.editedUser.country ||'';
                this.status = this.editedUser.status;
                this.image = this.editedUser.image ||'';
                this.documents = this.editedUser.documents ||'';

                this.company_name = this.editedUser.company_name ||'';
                this.rfc = this.editedUser.rfc;
                this.trade_name = this.editedUser.trade_name ||'';
                this.office_phone = this.editedUser.office_phone ||'';
           
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });

      axios
        .get(`membershowvehicle/${userId}`)
        .then((response) => {
          this.vehicles = response.data.data;
          this.company_name = vehicles.company_name ||'';
                this.model = this.vehicles?.model || '';
                this.color = vehicles.color;
                this.engine_number = vehicles.engine_number;
                this.insurance_expiration_date = vehicles.insurance_expiration_date;
                this.license_plate = vehicles.license_plate;
                this.brand = vehicles?.brand || '';
                this.mileage = vehicles.mileage; 
                this.types = vehicles.types;
                this.registration_number = vehicles.registration_number;
                this.vin = vehicles.vin ||'';
                this.uic = vehicles.uic ||'';
                this.year = vehicles.year;
                this.status = vehicles.status;
                this.image = vehicles.image ||'';
                this.documents = vehicles.documents ||'';
        })
        .catch((error) => {
          console.error("Error fetching vehicle data:", error);
        });
    },
    computed: {
    formattedPhoneNumber: {
        get() {
            return this.phone_number;
        },
        set(value) {
            this.phone_number = value.replace(/^\+/, '');
        },
    },
    },
    mounted() {
    this.fetchData(); 
   },
    methods: {
      isImage(fileName) {
        return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
      },
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
       
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
        uploadDocuments(vehicleId) {
      this.resetUploadDocument();
      this.vehicleId = vehicleId;
      this.modalVisibleDoument = true;
      },
      onDocumentsChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.documents = file;
        }
      },

    },
  };
  </script>
  