<template>
  <b-row class="hp-authentication-page">
    <left-item />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">Login</h1>
          <!-- <h1 class="mb-0 mb-sm-24">{{ $t('Login') }}</h1> -->
          <p class="mt-sm-8 mt-sm-0 text-black-60">
           <!-- {{ $t(' Welcome back, please login to your account.') }} -->
           Welcome back, please login to your account.
          </p>

          <b-form class="user" @submit.prevent="login">

            <b-form-group label="Email :" label-for="email" class="mb-16">
              <b-form-input id="email" type="text" v-model="email" required></b-form-input>
            </b-form-group>
          
            
            <b-form-group label="Password :" label-for="password" class="mb-16">
              <b-form-input id="password" type="password" v-model="password" required></b-form-input>
            </b-form-group>

            <b-row align-v="center" align-h="between" class="mb-16">
              <b-col class="hp-flex-none w-auto">
                <b-form-checkbox>Remember me</b-form-checkbox>
              </b-col>
            </b-row>

            <!-- <b-button type="submit" variant="primary"> -->
              <b-button type="submit" variant="primary" class="w-100 mb-2">
               Sign in 
            </b-button>

            
              <!-- <b-button @click="changeLanguage('sp')" variant="outline-primary" class="w-100 mb-2 py-2 text-uppercase shadow">
              Switch to Spanish
            </b-button> -->
        
                <!-- <b-button @click="changeLanguage('en')" variant="outline-secondary" class="w-100 py-2 text-uppercase shadow">
                Switch to English
              </b-button> -->
          </b-form>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
// import axios from "../../../axios";
import LeftItem from "../LeftItem.vue";
import FooterItem from '../FooterItem.vue';
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BToast,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BToast, 
    LeftItem,
    FooterItem,
  },

  methods: {
  async login() {
    try {
      const response = await axios.post("login", {
        email: this.email,
        password: this.password,
        type: this.type, 
      });

      const userType = response.data.data.user.type;

      // Save the token and user type in localStorage
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("type", userType);

      // Set the user in the Vuex store
      this.$store.dispatch("user", response.data.data.user);

      // Redirect based on user type
      if (userType === 'super_admin') {
        this.$router.push("/dashboards");
      } else if (userType === 'police') {
        this.$router.push("/policedashboard");
      } else {
        this.$bvToast.toast("Unknown user type.", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    } catch (error) {
      this.$bvToast.toast("Login failed. Please check your credentials.", {
        title: "Error",
        variant: "primary",
        solid: true,
      });
    }
  },
  // changeLanguage(lang) {
  //     this.$i18n.locale = lang;
  //   },
}

};
</script>
