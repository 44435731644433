<template>
    <b-card>
      <!-- filter  -->
      <div v-if="loading" class="text-center mt-4">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div class="col-12 mt-16">
        <div>
          <b-row class="align-items-center">
            <b-col lg="4" class="my-1">
              <b-form-group label="" label-for="filter-input" label-cols-sm="1" label-align-sm="right" label-size="sm"
                class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"
                    placeholder="Type to Search"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="applyFilter" :disabled="!filter" variant="primary">Apply</b-button>
                    <b-button :disabled="!filter" @click="clearFilter">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
              <!-- .. Filter  for   date-->
              <b-col lg="" class="my-1">
                    <b-form-input id="start-date" v-model="startDateFilter" type="date"
                        placeholder="Select start date"></b-form-input>
                </b-col>
                <b-col lg="3" class="my-1">
                    <b-form-input id="end-date" v-model="endDateFilter" type="date" placeholder="Select end date"
                        @change="changeDate"></b-form-input>
                </b-col>
                    <!-- Filter  for   date-  -->
            <b-col lg="2" class="my-1 d-flex justify-content-end">
        
              <b-button @click="exportAllDataToCSV" variant="primary" class="mb-8 mr-8">Export</b-button>
            </b-col>           
          </b-row>
        </div>
      </div>
      <!-- filter end -->
      <b-row>
        <div class="col-12 mt-16">
          <b-table id="dataTable" :items="users" :busy.sync="loading" :fields="fields" :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty responsive>
           
            <template #cell(status)="row">
              <b-button @click="handleButtonClick(row.item)"
                :variant="row.item.status === 'Pending' ? 'warning' : 'primary'" class="mb-8 mr-8">
                {{ row.item.status }}
              </b-button>
              <b-modal v-model="showPaymentConfirmation" title="Change Payment Status Confirmation">
                <p>Are you sure you want to change status to {{ newPaymentStatus }}?</p>
                <b-spinner v-if="loading" label="Processing..."></b-spinner>
                <template #modal-footer>
                  <b-button :disabled="loading" variant="primary" @click="confirmPaymentStatus">Confirm</b-button>
                  <b-button :disabled="loading" variant="primary" @click="cancelPaymentStatus">Cancel</b-button>
                </template>
              </b-modal>
            </template>
  
            <template #cell(actions)="row">
  
              <b-button @click="showUser(row.item.id)" variant="link" class="p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" style="
                  color: rgba(0, 255, 195, 0.87);
                  margin-left: 6px;
                  margin-bottom: 10px;
                " class="bi bi-eye" viewBox="0 0 16 16">
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              </b-button>
              <!-- delete -->
              <b-button @click="showDeleteConfirmation(row.item.id)" variant="link" class="p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                  style="color: red; margin-left: 6px; margin-bottom: 10px" class="bi bi-eye" viewBox="0 0 16 16">
                  <!-- ... your SVG path ... -->
                  <path
                    d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </b-button>
              <b-modal v-model="showDeleteConfirmations" title="Delete Confirmation">
                <p>Are you sure you want to delete this item?</p>
                <template #modal-footer>
                  <b-button variant="primary" @click="deleteItem(itemIdToDelete)">Delete</b-button>
                  <b-button variant="primary" @click="showDeleteConfirmations = false">Cancel</b-button>
  
                </template>
              </b-modal>
              <!-- end delete -->
  
              <!-- Edit Code here  -->
              <b-button @click="editUser(row.item.id)" variant="link" class="p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                  style="color: orange; margin-left: 10px; margin-bottom: 10px" class="bi bi-pencil" viewBox="0 0 16 16">
                  <!-- ... your existing SVG path ... -->
                  <path
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </b-button>
              &nbsp;
              <b-button @click="requestApproved(row.item.id)" variant="primary"
                            class="btn-sm mb-8 mr-2">See  Vehicle
                        </b-button>
            </template>
                    <template #cell(first_name)="row">
                        {{ row.item.first_name ||'N/A' }}
                    </template>

                    <template #cell(email)="row">
                        {{ row.item.email ||'N/A' }}
                    </template>
                    <template #cell(country)="row">
                        {{ row.item.country ||'N/A' }}
                    </template>
                    <template #cell(city)="row">
                        {{ row.item.city ||'N/A' }}
                    </template>
                    <template #cell(type)="row">
                        {{ row.item.type ||'N/A' }}
                    </template>
                    <template #cell(phone_number)="row">
                        {{ row.item.phone_number ||'N/A' }}
                    </template>
                  
            <template v-slot:cell(created_at)="data">
                    {{ formatDate(data.item.created_at) }}
                    </template>
                    <template v-slot:cell(created_at_time)="data">
                    {{ formatTime(data.item.created_at) }}
                    </template>
  
  
            <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search"
                  placeholder="Type to Search"></b-form-input>
  
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-table>
          <div class="mx-8 d-flex justify-content-end">
            <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" aria-controls="dataTable"
              @change="pageChanged"></b-pagination>
  
          </div>
          <b-row class="mt-16 align-items-center justify-content-end">
            <b-row>
  
            </b-row>
          </b-row>
        </div>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BSpinner,
  } from "bootstrap-vue";
  import axios from "../../../axios";
  import Papa from "papaparse";

  export default {
    data() {
      return {
        lastPage: 0,
        firstPageUrl: null,
        lastPageUrl: null,
        nextPageUrl: null,
        prevPageUrl: null,
        isLoading: false,
        perPage: 10,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        selectedCardOption: "",
        rowToUpdate: null,
        modalVisible: false,
        editMode: false,
        users: [],
        fields: [
          { key: "id", sortable: true },
          { key: "first_name",label:'Name', sortable: true },
          { key: "email", sortable: true },
          { key: "phone_number", sortable: true },
          { key: "city", sortable: true },
          { key: "country", sortable: true },
          { key: "created_at", label: "Date", formatter: this.formatDate },
          { key: "created_at_time", label: "Time" },
          { key: "type", label: "User Type" },
          { key: "status", sortable: true },
          { key: "vehicle_pending_and_rejected_count", label:"Pending Vehicle", sortable: true },
          { key: "actions", label: "Actions" },
        ],
  
        filter: "", 
        total: 0, 
        showDeleteConfirmations: false,
        itemIdToDelete: null,
        showPaymentConfirmation: false, 
        newPaymentStatus: '',
        loading: false,
        your_vehicle_id: null,
        startDateFilter: null,
        endDateFilter: null,
        ended_date: "",
        selected_date: "",
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BTable,
      BFormGroup,
      BInputGroup,
      BFormInput,
      BFormSelect,
      BPagination,
      BInputGroupAppend,
      BSpinner,
    },
    computed: {
      sortOptions() {
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      rows() {
        return this.users.length;
      },
    },
    mounted() {

      this.fetchData();
    },
  
    methods: {
      clearFilter() {
            this.filter = '';     
            this.currentPage = 1;   
            this.fetchData();       
        },
      async fetchData() {
        this.loading = true;
        let apiUrl = "ReguestGet";
        try {
          const response = await axios.get(apiUrl, {
            params: { page: this.currentPage, per_page: this.perPage,
              ended_date: this.endDateFilter,
              selected_date: this.startDateFilter,
             }
          });
  
          this.users = response.data.data;
          this.total = response.data.total;
          this.currentPage = response.data.current_page;
          this.perPage = response.data.per_page;
          this.lastPage = response.data.last_page;
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this.loading = false;
        }
      },
      applyFilter() {
        // Handle applying the filter
        this.loading = true;
        let apiUrl = "ReguestGet";
        axios
          .get(apiUrl, {
            params: {
              search: this.filter,
            },
          })
          .then((response) => {
            this.users = response.data.data;
            this.total = response.data.total;
            this.currentPage = response.data.current_page;
          })
          .catch((error) => {
            console.error("Error applying filter:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      pageChanged(newPage) {
        this.currentPage = newPage;
        this.fetchData();
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      codeClick() {
        this.codeActive = !this.codeActive;
      },
  
      exportAllDataToCSV() {
              this.fetchAllParents((allParents) => {
                  const csv = Papa.unparse(allParents);
                  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.setAttribute("download", "exported_data.csv");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              });
          },
          fetchAllParents(callback) {
              axios
                  .get("AllrequestDataForExport", { params: { per_page: this.totalRows } })
                  .then((response) => {
                      callback(response.data.data);
                  })
                  .catch((error) => {
                   
                  });
          },
          showUser(userId) {
        this.$router.push({ name: "ViewRequest", params: { id: userId } });
      },

      showDeleteConfirmation(itemId) {
        this.itemIdToDelete = itemId;
        this.showDeleteConfirmations = true;
      },
      deleteItem(itemId) {
        this.itemIdToDelete = itemId; 
        axios
          .delete(`registration/${itemId}`)
          .then((response) => {
            this.showDeleteConfirmations = false;
            this.fetchData(); 
          })
          .catch((error) => {
         
          });
      },
  
      downloadFile(fileUrl) {
        const link = document.createElement("a");
        link.href = "https://wossdb.wossautoid.com/" + fileUrl;
        link.download = "downloaded_file";
        link.target = "_blank";
        link.click();
      },
  
      handleButtonClick(item) {
        this.updateStatus(item);
        this.toggleCardModal(item);
      },
      updateStatus(user) {
        user.status = user.status === "Approved" ? "Pending" : "Approved";
        axios
          .post(`Reguest_Approve_status/${user.id}`, user)
          .then((response) => {
           
          })
          .catch((error) => {
         
          });
      },
      editUser(userId) {
        this.$router.push({ name: "EditRequest", params: { id: userId } });
      },
      toggleCardModal(item) {
        this.selectedCardOption = ""; 
        this.rowToUpdate = item; 
        this.isCardModalVisible = true; 
      },
  
      handleButtonClick(item) {
        this.newPaymentStatus = item.status === 'Pending' ? 'Approved' : 'Pending';
        this.rowToUpdate = item;
        this.showPaymentConfirmation = true;
      },
  
      confirmPaymentStatus() {
        this.loading = true; 
        this.rowToUpdate.status = this.newPaymentStatus;
        axios
          .post(`updateStatus/${this.rowToUpdate.id}`, { status: this.newPaymentStatus })
          .then((response) => {
          
            this.showPaymentConfirmation = false;
          })
          .catch((error) => {
           
            this.showPaymentConfirmation = false;
          })
          .finally(() => {
            this.loading = false; 
          });
      },
      cancelPaymentStatus() {

        this.showPaymentConfirmation = false;
      },
      requestApproved(userId) {
            this.$router.push({ name: "ViewAllVehicle", params: { id: userId } });
        },
          
              formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
    }, 
     
        formatTime(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); 
    },
    changeDate() {
            const userId = this.$route.params.id;
            this.fetchData(userId);
        },
  
    },
  
  
  };
  </script>
  