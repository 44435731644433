export default {
  // header: "Dashboards",
  children: [
    {
      id: "/policedashboard",
      title: "PoliceDashboard",
      iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
      navLink: "/policedashboard",
    },
    
           // Vehicle code here 
     {
      id: "vehicle",
      title: "Vehicle",
      icon: "Curved-AddUser",
      
      children: [
    
        {
          id: "vehicle",
          title: "List Theft Vehicle",
          navLink: "/list/theft/vehicle",
        }, 
        {
          id: "vehicle",
          title: "Recover Vehicle",
          navLink: "/recover/list/police/vehicle",
        }, 
        {
          id: "vehicle",
          title: "Police All Notification",
          navLink: "/police/all/notification",
        }, 

        {
          id: "vehicle",
          title: "Stolen List Tracker",
          navLink: "/list/tracking/stolen/police",
        }, 
      ],
    },  
    

  ]
}
